import React, { useState } from "react";
import { Card, Row, Col, Form, DatePicker, Select, Radio, Button, Table, Divider } from "antd";

const SpectacleLensDetailUsageReport: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    { title: "S/G", dataIndex: "sg", key: "sg" },
    { title: "Lens Code", dataIndex: "lensCode", key: "lensCode" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Sphere", dataIndex: "sphere", key: "sphere" },
    { title: "Cyl", dataIndex: "cyl", key: "cyl" },
    { title: "Add", dataIndex: "add", key: "add" },
    { title: "Qty", dataIndex: "qty", key: "qty" },
  ];

  const data = [
    {
      key: "1",
      sg: "S",
      lensCode: "A3",
      description: "S 1.5 Stock HC",
      sphere: "+0.25",
      cyl: "-",
      add: "-",
      qty: 3,
    },
    {
      key: "2",
      sg: "S",
      lensCode: "A5",
      description: "A 3.5 Stock HC",
      sphere: "+0.5",
      cyl: "-0.75",
      add: "-",
      qty: 6,
    },
    {
      key: "3",
      sg: "S",
      lensCode: "A401",
      description: "A 1.5 Stock HC",
      sphere: "+1.25",
      cyl: "-0.25",
      add: "-",
      qty: 7,
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  label="Report From Date"
                  name="reportFromDate"
                  rules={[{ required: true, message: "Please select the start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Report To Date"
                  name="reportToDate"
                  rules={[{ required: true, message: "Please select the end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Spec Lens Code" name="specLensCode">
                  <Select placeholder="Select Lens Code" allowClear>
                    <Select.Option value="code1">Code 1</Select.Option>
                    <Select.Option value="code2">Code 2</Select.Option>
                    <Select.Option value="code3">Code 3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Supplier" name="supplier">
                  <Select placeholder="Select Supplier" allowClear>
                    <Select.Option value="supplier1">Supplier 1</Select.Option>
                    <Select.Option value="supplier2">Supplier 2</Select.Option>
                    <Select.Option value="supplier3">Supplier 3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">To Screen</Radio>
                    <Radio value="printer">To Printer</Radio>
                    <Radio value="printerPrompt">To Printer with Prompt</Radio>
                    <Radio value="excel">To Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }}>
                Spectacle - Lens Detail Usage Report From 10-10-2024 To 12-12-2024
              </Divider>
              <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col span={12}>
                  <strong>Supplier:</strong> <span>Supplier Name</span>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <strong>Lens Code:</strong> <span>Lens Code</span>
                </Col>
              </Row>
              <Table columns={columns} dataSource={data} pagination={false} bordered />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SpectacleLensDetailUsageReport;
