import React, { useState } from "react";
import { Card, Form, DatePicker, Radio, Button, Table, Divider, Row, Col } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { Dayjs } from "dayjs";

interface LensExtraData {
  key: string;
  dispenser: string;
  patientName: string;
  orderDate: string;
  jobNo: number;
  lensExtra: string;
  extra: number;
}

const SpectacleLensExtraReport: React.FC = () => {
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns: ColumnsType<LensExtraData> = [
    { title: "Dispenser", dataIndex: "dispenser", key: "dispenser" },
    { title: "Patient Name", dataIndex: "patientName", key: "patientName" },
    { title: "Order Date", dataIndex: "orderDate", key: "orderDate" },
    { title: "Job No.", dataIndex: "jobNo", key: "jobNo" },
    { title: "Lens Extra", dataIndex: "lensExtra", key: "lensExtra" },
    {
      title: "Extra $",
      dataIndex: "extra",
      key: "extra",
      render: (value) => value.toFixed(2),
    },
  ];

  const tablesData = [
    {
      data: [
        {
          key: "1",
          dispenser: "-",
          patientName: "Shyam Maharjan",
          orderDate: "10/10/2024",
          jobNo: 24,
          lensExtra: "Grind",
          extra: 115.0,
        },
        {
          key: "2",
          dispenser: "-",
          patientName: "Ram Bahadur",
          orderDate: "25/10/2024",
          jobNo: 26,
          lensExtra: "-MC -Multicoat",
          extra: 698.0,
        },
        {
          key: "3",
          dispenser: "-",
          patientName: "Shreya Maharjan",
          orderDate: "12/11/2024",
          jobNo: 27,
          lensExtra: "-MC -VP",
          extra: 269.55,
        },
      ],
      total: 115.0 + 698.0 + 269.55,
    },
    {
      data: [
        {
          key: "4",
          dispenser: "AL",
          patientName: "Sumesh Shakya",
          orderDate: "15/10/2024",
          jobNo: 78,
          lensExtra: "Grind",
          extra: 345.9,
        },
      ],
      total: 345.9,
    },
    {
      data: [
        {
          key: "5",
          dispenser: "CN",
          patientName: "Riya Karki",
          orderDate: "10/10/2024",
          jobNo: 24,
          lensExtra: "Grind",
          extra: 115.0,
        },
        {
          key: "6",
          dispenser: "CN",
          patientName: "Sam Basnet",
          orderDate: "25/10/2024",
          jobNo: 26,
          lensExtra: "-MC -Multicoat",
          extra: 200.0,
        },
      ],
      total: 115.0 + 200.0,
    },
  ];

  return (
    <Card style={{ margin: 24, borderRadius: 4, boxShadow: "0px 8px 40px -10px #00000014" }}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End Date" name="endDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Report Destination" name="destination" initialValue="screen">
              <Radio.Group>
                <Radio value="screen">To Screen</Radio>
                <Radio value="printer">To Printer</Radio>
                <Radio value="printerPrompt">To Printer with Prompt</Radio>
                <Radio value="excel">To Excel Spreadsheet</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Button
            onClick={() => {
              form.resetFields();
              setShowReport(false);
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={handleGenerateReport}>
            Generate Report
          </Button>
        </Row>
      </Form>

      {showReport && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Spectacle - Lens Extra Report From 10-10-2024 To 12-12-2024
          </Divider>
          {tablesData.map((table, index) => (
            <React.Fragment key={index}>
              <Table
                columns={columns}
                dataSource={table.data}
                pagination={false}
                bordered
                style={{ marginBottom: 24 }}
                summary={() => (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={4} />
                      <Table.Summary.Cell index={5}>
                        <strong>{table.total.toFixed(2)}</strong>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </Card>
  );
};

export default SpectacleLensExtraReport;
