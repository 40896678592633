import React, { useState } from "react";
import { Card, Row, Col, Menu, Divider, Button, Table, Form, DatePicker, Radio } from "antd";

const SpectacleFrameReportPage: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState<"byPrescriber" | "byDispenser">("byPrescriber");
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
    form.resetFields();
    setShowReport(false);
  };

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // Prescriber Report Configuration
  const prescriberColumns: any = [
    { title: "Optometrist", dataIndex: "optometrist", key: "optometrist" },
    { title: "Total No. of Patients", dataIndex: "totalPatients", key: "totalPatients" },
    { title: "Total No. of Spectacles", dataIndex: "totalSpectacles", key: "totalSpectacles" },
    { title: "No. of Own Frames", dataIndex: "ownFrames", key: "ownFrames" },
    { title: "No. of New Frames", dataIndex: "newFrames", key: "newFrames" },
    {
      title: "Total Exc. Tax Price of New Frames $",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (value: number) => value.toFixed(2),
    },
    {
      title: "Avg Exc. Tax Price per Frame $",
      dataIndex: "avgPrice",
      key: "avgPrice",
      render: (value: number) => value.toFixed(2),
    },
  ];

  const prescriberData: any = [
    {
      key: "1",
      optometrist: "CN",
      totalPatients: 3,
      totalSpectacles: 3,
      ownFrames: 3,
      newFrames: 3,
      totalPrice: 3699.52,
      avgPrice: 35.26,
    },
    {
      key: "2",
      optometrist: "DT",
      totalPatients: 2,
      totalSpectacles: 2,
      ownFrames: 2,
      newFrames: 2,
      totalPrice: 4456.39,
      avgPrice: 88.25,
    },
    {
      key: "3",
      optometrist: "Sub Total",
      totalPatients: 10,
      totalSpectacles: 10,
      ownFrames: 10,
      newFrames: 10,
      totalPrice: 8563.66,
      avgPrice: 126.33,
    },
  ];

  // Dispenser Report Configuration (from image data)
  const dispenserColumns: any = [
    { title: "Dispenser", dataIndex: "dispenser", key: "dispenser" },
    { title: "Total No. of Spectacles", dataIndex: "totalSpectacles", key: "totalSpectacles" },
    { title: "No. of Own Frames", dataIndex: "ownFrames", key: "ownFrames" },
    { title: "No. of New Frames", dataIndex: "newFrames", key: "newFrames" },
    {
      title: "Total Exc. Tax Price of New Frames $",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (value: number) => value.toFixed(2),
    },
    {
      title: "Avg Exc. Tax Price per Frame $",
      dataIndex: "avgPrice",
      key: "avgPrice",
      render: (value: number) => value.toFixed(2),
    },
  ];

  const dispenserData: any = [
    {
      key: "1",
      dispenser: "AL",
      totalSpectacles: 3,
      ownFrames: 3,
      newFrames: 3,
      totalPrice: 3659.52,
      avgPrice: 35.26,
    },
    {
      key: "2",
      dispenser: "CL",
      totalSpectacles: 2,
      ownFrames: 2,
      newFrames: 2,
      totalPrice: 4456.39,
      avgPrice: 88.25,
    },
    {
      key: "3",
      dispenser: "Sub Total",
      totalSpectacles: 10,
      ownFrames: 10,
      newFrames: 10,
      totalPrice: 8563.66,
      avgPrice: 126.33,
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="byPrescriber"
              style={
                selectedKey === "byPrescriber" ? { backgroundColor: "#18B6DA", color: "#fff" } : {}
              }
            >
              By Prescriber
            </Menu.Item>
            <Menu.Item
              key="byDispenser"
              style={
                selectedKey === "byDispenser" ? { backgroundColor: "#18B6DA", color: "#fff" } : {}
              }
            >
              By Dispenser
            </Menu.Item>
          </Menu>
        </Card>
      </Col>

      <Col span={16}>
        <Card style={{ height: "calc(100vh - 138px)", overflowY: "auto" }}>
          <Divider orientation="left" orientationMargin="0">
            Spectacle Frame Report{" "}
            {selectedKey === "byPrescriber" ? "By Prescriber" : "By Dispenser"}
          </Divider>

          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">Screen</Radio>
                    <Radio value="printer">Printer</Radio>
                    <Radio value="printerPrompt">Printer with Prompt</Radio>
                    <Radio value="excel">Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }} />
              <Table
                columns={selectedKey === "byPrescriber" ? prescriberColumns : dispenserColumns}
                dataSource={selectedKey === "byPrescriber" ? prescriberData : dispenserData}
                pagination={false}
                bordered
                summary={
                  selectedKey === "byPrescriber"
                    ? () => (
                        <Table.Summary.Row>
                          {prescriberColumns.map((_: any, index: any) => (
                            <Table.Summary.Cell key={index} index={index}>
                              {index === 0 && <strong>Grand Total</strong>}
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                      )
                    : undefined
                }
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SpectacleFrameReportPage;
