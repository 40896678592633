import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  Table,
  Divider,
  Typography,
  Radio,
  Descriptions,
  Select,
} from "antd";

const SundriesUsageReport: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);
  const [reportDates, setReportDates] = useState({ from: "", to: "" });

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      const values = await form.validateFields();
      setReportDates({
        from: values.reportFrom.format("DD-MM-YYYY"),
        to: values.reportTo.format("DD-MM-YYYY"),
      });
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Code", dataIndex: "code", key: "code" },
    { title: "Supp.", dataIndex: "supplier", key: "supplier" },
    { title: "Qty", dataIndex: "qty", key: "qty", align: "right" as const },
    { title: "Avg Cost $ Exc. Tax", dataIndex: "costExc", key: "costExc", align: "right" as const },
    { title: "Avg Cost $ Inc. Tax", dataIndex: "costInc", key: "costInc", align: "right" as const },
    { title: "Avg Sales Price $", dataIndex: "price", key: "price", align: "right" as const },
    { title: "Gross Profit", dataIndex: "profit", key: "profit", align: "right" as const },
  ];

  const data = [
    {
      key: "1",
      brand: "-",
      description: "Uncoded Sundry Items",
      code: "Uncoded Item",
      supplier: "-",
      qty: 12,
      costExc: 12,
      costInc: 14,
      price: 28,
      profit: "100%",
    },
    {
      key: "total",
      brand: "Total",
      qty: 15,
      costExc: 15,
      costInc: 15,
      isTotal: true,
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Report From"
                  name="reportFrom"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Report To"
                  name="reportTo"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Sundry Group" name="sundrygroup">
                  <Select style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Supplier" name="supplier">
                  <Select style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">To Screen</Radio>
                    <Radio value="printer">To Printer</Radio>
                    <Radio value="printerPrompt">To Printer with Prompt</Radio>
                    <Radio value="excel">To Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <div style={{ marginTop: 24 }}>
              <Divider orientation="left">
                Sundries (Coded Items) - Usage Report From {reportDates.from} To {reportDates.to}
              </Divider>

              <Typography.Title level={5}>Sundry Group: -</Typography.Title>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                components={{
                  body: {
                    cell: (props: any) => {
                      const record = props.children?.props?.record;
                      return (
                        <td
                          {...props}
                          style={{
                            ...props.style,
                            backgroundColor: record?.isTotal ? "#f0f0f0" : "inherit",
                            fontWeight: record?.isTotal ? 600 : "normal",
                            padding: "8px 16px",
                          }}
                        />
                      );
                    },
                  },
                }}
              />

              <Divider style={{ margin: "24px 0" }} />
              <Card style={{ backgroundColor: "#E3F7FC", border: "none" }}>
                <Descriptions layout="vertical" column={3} bordered={false} size="middle">
                  <Descriptions.Item label="Profits">
                    <strong style={{ fontSize: "1rem" }}>66780</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Gross Profit (Profit/Changes)">
                    <strong style={{ fontSize: "1rem" }}>100%</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Gross Margin (Profit/Cost)">
                    <strong style={{ fontSize: "1rem" }}>0.00%</strong>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SundriesUsageReport;
