import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, DatePicker, Button, Radio, Descriptions } from "antd";
import dayjs from "dayjs";

const PracticeSummaryReport: React.FC = () => {
  const [reportDateRange, setReportDateRange] = useState<[string, string] | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setReportDateRange([
      dayjs(values.reportFrom).format("DD-MM-YYYY"),
      dayjs(values.reportTo).format("DD-MM-YYYY"),
    ]);
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Practice Summary Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Report From"
                name="reportFrom"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Report To"
                name="reportTo"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportDateRange && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Practice Summary Report from {reportDateRange[0]} to {reportDateRange[1]}
          </Divider>

          <h3>Patient Payment (Inc Tax)</h3>
          <Descriptions bordered column={1} layout="vertical">
            <Descriptions.Item label="Priv Consult">80.00</Descriptions.Item>
            <Descriptions.Item label="Contact Lenses">80.00</Descriptions.Item>
            <Descriptions.Item label="Spectacles">120.00</Descriptions.Item>
            <Descriptions.Item label="Sunglasses">160.00</Descriptions.Item>
            <Descriptions.Item label="Sundries">200.00</Descriptions.Item>
            <Descriptions.Item label="Refund">120.00</Descriptions.Item>
            <Descriptions.Item label="Payment - Misc">300.00</Descriptions.Item>
            <Descriptions.Item label="Bulk-Bill Payment">280.00</Descriptions.Item>
            <Descriptions.Item label="Total Payment (Inc. Tax)">1150.00</Descriptions.Item>
          </Descriptions>

          <h3 style={{ marginTop: "24px" }}>Jobbing (Exc Tax)</h3>
          <Descriptions bordered column={1} layout="vertical">
            <Descriptions.Item label="Frame Count">100</Descriptions.Item>
            <Descriptions.Item label="Frame Sales">160.00</Descriptions.Item>
            <Descriptions.Item label="Lenses">180.00</Descriptions.Item>
            <Descriptions.Item label="Extras">160.00</Descriptions.Item>
            <Descriptions.Item label="Spec Discount">-200.00</Descriptions.Item>
            <Descriptions.Item label="Spec Cost">120.00</Descriptions.Item>
            <Descriptions.Item label="No. of Specs">80</Descriptions.Item>
            <Descriptions.Item label="CL Sale">260.00</Descriptions.Item>
            <Descriptions.Item label="CL Cost">108.00</Descriptions.Item>
            <Descriptions.Item label="CL Discount">Pair of CL</Descriptions.Item>
            <Descriptions.Item label="Sunny Sale">800.00</Descriptions.Item>
            <Descriptions.Item label="Sunny Cost">200.00</Descriptions.Item>
            <Descriptions.Item label="Sunglass Count">-</Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </>
  );
};

export default PracticeSummaryReport;
