import React, { useState } from "react";
import { Card, Row, Col, Form, DatePicker, Select, Radio, Button, Table, Divider } from "antd";

const ContactLensUsageReport: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    { title: "CL Code", dataIndex: "code", key: "code" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Qty", dataIndex: "qty", key: "qty", align: "right" as const },
    { title: "Cost $", dataIndex: "cost", key: "cost", align: "right" as const },
    { title: "RRP $", dataIndex: "rrp", key: "rrp", align: "right" as const },
    { title: "Gross Profit %", dataIndex: "profit", key: "profit", align: "center" as const },
  ];

  const data = [
    {
      key: "1",
      code: "AAAA",
      description: "Acuvu Oasis 1 Day 30 Packs",
      qty: 12,
      cost: 115.0,
      rrp: 115.0,
      profit: "50%",
    },
    {
      key: "2",
      code: "AAAAA",
      description: "Acuvu Oasis 1 Day 90 Packs",
      qty: 13,
      cost: 698.0,
      rrp: 698.0,
      profit: "60%",
    },
    {
      key: "3",
      code: "AAAAAA",
      description: "Acuvu Oasis 1 Day Moist 30 Packs",
      qty: 15,
      cost: 269.55,
      rrp: 269.55,
      profit: "70%",
    },
    {
      key: "4",
      code: "Total",
      description: "",
      qty: 40,
      cost: 1129.55,
      rrp: 1129.55,
      profit: "60%",
      className: "total-row",
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Report From"
                  name="reportFrom"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Report To"
                  name="reportTo"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">To Screen</Radio>
                    <Radio value="printer">To Printer</Radio>
                    <Radio value="printerPrompt">To Printer with Prompt</Radio>
                    <Radio value="excel">To Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }}>
                Contact Lens Usage Report With Prices From 10-10-2024 To 12-12-2024
              </Divider>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                rowClassName={(record) => record.className || ""}
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ContactLensUsageReport;
