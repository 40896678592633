import React, { useState } from "react";
import { DatePicker, Button, Card, Form, Row, Col } from "antd";
import styled from "@emotion/styled";

// Styled components
const Container = styled.div`
  padding: 20px;
`;

const ReportSection = styled(Card)`
  margin-top: 20px;
`;

const SpectacleExportJobs: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleGenerateReport = async () => {
    const values = await form.validateFields();
    setShowReport(true);
  };

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  return (
    <Container>
      <Card>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Please select start date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Please select end date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Form.Item>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Export Jobs
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </Container>
  );
};

export default SpectacleExportJobs;
