import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Button,
  Table,
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Space,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { StyledForm } from "../styles";
import dayjs, { Dayjs } from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

interface SpectacleDetails {
  jobDate: Dayjs;
  specDueDate: Dayjs;
  dispenser: string;
  jobType: string;
  rxByDate: Dayjs;
  po: string;
  category: string;
  status: string;
  saleBy: string;
  rxBy: string;
  monoR: string;
  monoL: string;
  labDue: Dayjs;
  labToSendSpectacles: boolean;
  frameCode: string;
  framePrice: number;
  fittingPrice: number;
  discount: number;
  frameSize: string;
  depth: string;
  ed: string;
  type: string;
  shapeNo: string;
  other: string;
  reorderNote: string;
  extraLenses: string;
  labToApply: string;
  lensPrice: number;
  others: string;
  fittingType: string;
  fittingTypeBy: string;
  othersLensPrice: number;
  discountReason: string;
  lensDiscount: number;
  overallDiscount: number;
  totalPrice: number;
  gstPercentage: number;
  gstAmount: number;
  jobReady: string;
  jobReadyBy: string;
  collectedDate: Dayjs;
  collectedBy: string;
  followUpDate: Dayjs;
  followUpBy: string;
  lastNotified: Dayjs;
  notificationsSent: number;
  comments: string;
  labInstructions: string;
  notificationDetails: string;
}

// Sample data associated with each date
const spectacleDetailsData: Record<string, SpectacleDetails> = {
  "2024-10-01": {
    jobDate: dayjs("2024-10-01"),
    specDueDate: dayjs("2024-10-05"),
    dispenser: "Dispenser A",
    jobType: "Type A",
    rxByDate: dayjs("2024-10-02"),
    po: "PO1234",
    category: "Single Vision",
    status: "Active",
    saleBy: "Salesperson X",
    rxBy: "Dr. Smith",
    monoR: "1.00",
    monoL: "0.75",
    labDue: dayjs("2024-10-10"),
    labToSendSpectacles: true,
    frameCode: "Frame 101",
    framePrice: 100,
    fittingPrice: 50,
    discount: 10,
    frameSize: "Medium",
    depth: "12",
    ed: "15",
    type: "Regular",
    shapeNo: "SN-123",
    other: "N/A",
    reorderNote: "Reorder once stock is low",
    extraLenses: "N/A",
    labToApply: "Apply standard coating",
    lensPrice: 80,
    others: "N/A",
    fittingType: "Standard",
    fittingTypeBy: "Technician A",
    othersLensPrice: 40,
    discountReason: "Seasonal discount",
    lensDiscount: 5,
    overallDiscount: 15,
    totalPrice: 240,
    gstPercentage: 18,
    gstAmount: 43.2,
    jobReady: "Yes",
    jobReadyBy: "Technician A",
    collectedDate: dayjs("2024-10-03"),
    collectedBy: "Customer X",
    followUpDate: dayjs("2024-10-10"),
    followUpBy: "Salesperson Y",
    lastNotified: dayjs("2024-10-02"),
    notificationsSent: 1,
    comments: "First order",
    labInstructions: "Handle with care",
    notificationDetails: "sms",
  },
  "2024-10-10": {
    jobDate: dayjs("2024-10-10"),
    specDueDate: dayjs("2024-10-15"),
    dispenser: "Dispenser B",
    jobType: "Type B",
    rxByDate: dayjs("2024-10-12"),
    po: "PO5678",
    category: "Bifocal",
    status: "Inactive",
    saleBy: "Salesperson Y",
    rxBy: "Dr. Jones",
    monoR: "0.50",
    monoL: "0.75",
    labDue: dayjs("2024-10-18"),
    labToSendSpectacles: false,
    frameCode: "Frame 102",
    framePrice: 120,
    fittingPrice: 60,
    discount: 15,
    frameSize: "Large",
    depth: "13",
    ed: "16",
    type: "Premium",
    shapeNo: "SN-124",
    other: "N/A",
    reorderNote: "Reorder as needed",
    extraLenses: "Tinted lenses",
    labToApply: "Apply anti-glare coating",
    lensPrice: 100,
    others: "N/A",
    fittingType: "Premium",
    fittingTypeBy: "Technician B",
    othersLensPrice: 50,
    discountReason: "Holiday sale",
    lensDiscount: 10,
    overallDiscount: 20,
    totalPrice: 270,
    gstPercentage: 18,
    gstAmount: 48.6,
    jobReady: "No",
    jobReadyBy: "Technician B",
    collectedDate: dayjs("2024-10-14"),
    collectedBy: "Customer Y",
    followUpDate: dayjs("2024-10-20"),
    followUpBy: "Salesperson Z",
    lastNotified: dayjs("2024-10-10"),
    notificationsSent: 2,
    comments: "Urgent order",
    labInstructions: "Ensure proper fitting",
    notificationDetails: "email",
  },
  // You can add more sample data here following the same format
};

const SpectacleDetailsPage = () => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
  const [hasNavigated, setHasNavigated] = useState(false);

  const columns = [
    {
      title: "Rx",
      dataIndex: "rx",
      render: (text: string) => text,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },

    {
      title: "Sphere",
      dataIndex: "sphere",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Cyl",
      dataIndex: "cyl",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Axis",
      dataIndex: "axis",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Add",
      dataIndex: "add",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Inter",
      dataIndex: "inter",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "H Prism",
      dataIndex: "hPrism",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "V Prism",
      dataIndex: "vPrism",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
  ];

  const rxData = [
    { key: "1", rx: "Right" },
    { key: "2", rx: "Left" },
  ]; // Placeholder data for two rows

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Form Values:", values);
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const columns2 = [
    {
      title: "Eye",
      dataIndex: "eye",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Lens Code",
      dataIndex: "lensCode",
      render: () => (
        <Select placeholder="Select Lens Code">
          <Option value="code1">Code 1</Option>
          <Option value="code2">Code 2</Option>
        </Select>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Lens Description",
      dataIndex: "lensDescription",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Lens Size",
      dataIndex: "lensSize",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Seg Size",
      dataIndex: "segSize",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Seg Ht",
      dataIndex: "segHt",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "OC Ht",
      dataIndex: "ocHt",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Hor Decen",
      dataIndex: "horDecen",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Ver Decen",
      dataIndex: "verDecen",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "BC",
      dataIndex: "bc",
      render: () => <Input />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Lens Sup",
      dataIndex: "lensSup",
      render: () => (
        <Select placeholder="Select Supplier">
          <Option value="sup1">Supplier 1</Option>
          <Option value="sup2">Supplier 2</Option>
        </Select>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      render: () => (
        <Select placeholder="Select Supplier">
          <Option value="sup1">Supplier 1</Option>
          <Option value="sup2">Supplier 2</Option>
        </Select>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
    {
      title: "Lens Order Date",
      dataIndex: "lensOrderDate",
      render: () => <DatePicker />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
          padding: "2px",
        },
      }),
    },
    {
      title: "Lens Price",
      dataIndex: "lensPrice",
      render: () => <Input prefix="$" />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#d9d9d9",
          fontWeight: "bold",
        },
      }),
    },
  ];

  const lensData = [
    { key: "1", eye: "Right Eye" },
    { key: "2", eye: "Left Eye" },
  ];

  const availableDates: Dayjs[] = Object.keys(spectacleDetailsData).map((date) =>
    dayjs(date, "YYYY-MM-DD")
  );

  const disabledDate = (current: Dayjs): boolean => {
    return !availableDates.some((date) => date.isSame(current, "day"));
  };

  const handleDateChange = (date: Dayjs | undefined) => {
    setSelectedDate(date);
    if (date) {
      const history = spectacleDetailsData[date.format("YYYY-MM-DD")];
      if (history) {
        form.setFieldsValue(history);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  };
  const navigateEntries = (direction: "prev" | "next") => {
    let currentIndex;

    if (!selectedDate) {
      currentIndex = availableDates.length;
    } else {
      currentIndex = availableDates.findIndex((date) => date.isSame(selectedDate, "day"));
    }

    if (direction === "prev") {
      currentIndex = Math.max(0, currentIndex - 1);
    } else if (direction === "next") {
      currentIndex = Math.min(availableDates.length - 1, currentIndex + 1);
    }

    const newDate = availableDates[currentIndex];
    setHasNavigated(true);
    setSelectedDate(newDate);
    handleDateChange(newDate);
  };

  const prevButtonDisabled =
    availableDates.length === 0 || (selectedDate && availableDates[0].isSame(selectedDate, "day"));
  const nextButtonDisabled =
    availableDates.length === 0 ||
    (selectedDate && availableDates[availableDates.length - 1].isSame(selectedDate, "day")) ||
    !hasNavigated;

  return (
    <div>
      <Card
        style={{
          boxShadow: "0px 3px 30px -10px #00000013",
          borderRadius: "3px",
          padding: "0px",
        }}
        title={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space>
              <Button
                onClick={() => navigateEntries("prev")}
                icon={<LeftOutlined />}
                disabled={prevButtonDisabled}
                size="small"
              />
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                disabledDate={disabledDate}
                size="small"
              />
              <Button
                onClick={() => navigateEntries("next")}
                icon={<RightOutlined />}
                disabled={nextButtonDisabled}
                size="small"
              />
            </Space>
          </div>
        }
      >
        <StyledForm layout="horizontal" form={form} size="small">
          <Row gutter={[16, 16]}>
            {/* Job Date, Spec Due, @ */}
            <Col span={4}>
              <Form.Item label="Job Date" name="jobDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Spec Due" name="specDue">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="@" name="time">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Lab Due" name="labDue">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            {/* First Column */}
            <Col span={8}>
              <Row gutter={[16, 16]}>
                {/* Category, Job Type */}
                <Col span={12}>
                  <Form.Item
                    label="Category"
                    name="category"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Job Type"
                    name="jobType"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {/* Dispenser, Sale By */}
                <Col span={12}>
                  <Form.Item
                    label="Dispenser"
                    name="dispenser"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Sale By"
                    name="saleBy"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {/* PD, Use */}
                <Col span={12}>
                  <Form.Item label="PD" name="pd" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Use"
                    name="use"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 0]}>
                {/* Rx By, Mono R, L */}
                <Col span={12}>
                  <Form.Item
                    label="Rx: By"
                    name="rxBy"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Mono R"
                    name="monoR"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="L"
                    name="leftEye"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Second Column */}
            <Col span={8}>
              <Table
                columns={columns}
                dataSource={rxData}
                pagination={false}
                bordered
                size="small"
              />
            </Col>

            <Col span={8}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Frame Code"
                    name="frameCode"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Frame Supplier"
                    name="frameSupplier"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Frame Size"
                    name="frameSize"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Frame Order Date"
                    name="frameOrderDate"
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    label="Depth"
                    name="depth"
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="ED" name="ed" labelCol={{ span: 12 }} wrapperCol={{ span: 16 }}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Type"
                    name="type"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    label="Frame $"
                    name="framePrice"
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    label="Fitting $"
                    name="fittingPrice"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Discount"
                    name="frameDiscount"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Other"
                    name="other"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Reorder Note"
                    name="reorderNote"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Shape No"
                    name="shapeNo"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="frameToOrderFromLab"
                    valuePropName="checked"
                    wrapperCol={{ offset: 1, span: 24 }}
                  >
                    <Checkbox>Frame to Order from Lab?</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            columns={columns2}
            dataSource={lensData}
            pagination={false}
            bordered
            style={{ margin: "10px 0px 20px 0px" }}
            size="small"
          />

          <Row gutter={[48, 16]}>
            {/* First Column */}
            <Col span={12}>
              <Form.Item label="Extra 1">
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item name="extra1Left" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={18}>
                    <Form.Item name="extra1Right" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Extra 2">
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item name="extra1Left" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={18}>
                    <Form.Item name="extra1Right" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Extra 3">
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item name="extra1Left" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={18}>
                    <Form.Item name="extra1Right" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Others" name="others">
                <Input placeholder="Others" />
              </Form.Item>
              <Form.Item label="Lab Inst" name="labInst">
                <Input placeholder="Lab Instructions" />
              </Form.Item>
              <Form.Item name="frameOrder" valuePropName="checked">
                <Checkbox>Frame to Order from Lab?</Checkbox>
              </Form.Item>
            </Col>

            <Col span={12}>
              {["Lab to Apply", "Lab to Apply", "Lab to Apply"].map((label, index) => (
                <Row key={index} align="middle" gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item
                      label={label}
                      name={`labToApply${index + 1}`}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>
                  <Col span={8}>
                    <Form.Item
                      label="$"
                      name={`labToApplyPrice${index + 1}`}
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              ))}

              <Row align="middle" gutter={[16, 0]}>
                <Col span={8}>
                  <Form.Item
                    label="Fitting Type"
                    name="fittingType"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="fittingTypeBy"
                    label="By"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="fittingTypePrice"
                    label="$"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row align="middle" gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Discount Reason"
                    name="discountReason"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Lens Discount $"
                    name="lensDiscount"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row align="middle" gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Overall Discount %"
                    name="overallDiscount"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total $"
                    name="totalPrice"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item>
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={[18, 8]} align="middle">
                <Col span={24}>
                  <Row gutter={[4, 2]} align="middle">
                    {/* Job Ready Button */}
                    <Col span={8}>
                      <Button type="primary" style={{ width: "100%" }}>
                        Job Ready F6
                      </Button>
                    </Col>

                    {/* Date Picker 1 with Placeholder Label for Alignment */}
                    <Col span={5}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <DatePicker placeholder="Select date" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    {/* Input Field 1 with Label "By" */}
                    <Col span={5}>
                      <Form.Item
                        label="By"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    {/* GST Button */}
                    <Col span={6}>
                      <Button type="primary" style={{ width: "100%" }}>
                        GST
                      </Button>
                    </Col>

                    {/* Notified Button */}
                    <Col span={4}>
                      <Button type="primary" style={{ width: "100%" }}>
                        Notified F7
                      </Button>
                    </Col>

                    {/* Email and SMS Buttons */}
                    <Col span={2}>
                      <Button style={{ width: "100%" }}>Email</Button>
                    </Col>
                    <Col span={2}>
                      <Button style={{ width: "100%" }}>SMS</Button>
                    </Col>

                    {/* Date Picker 2 with Placeholder Label for Alignment */}
                    <Col span={5}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    {/* Input Field 2 without Label */}
                    <Col span={5}>
                      <Form.Item
                        label=" "
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    {/* Create Bill Button */}
                    <Col span={6}>
                      <Button type="primary" style={{ width: "100%" }}>
                        Create Bill
                      </Button>
                    </Col>

                    {/* Collected Button */}
                    <Col span={8}>
                      <Button type="primary" style={{ width: "100%" }}>
                        Collected F8
                      </Button>
                    </Col>

                    {/* Date Picker 3 with Placeholder Label for Alignment */}
                    <Col span={5}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <DatePicker placeholder="Select date" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    {/* Input Field 3 with Label "By" */}
                    <Col span={5}>
                      <Form.Item
                        label="By"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{ display: "flex", gap: "5px", justifyContent: "flex-end", marginTop: "8px" }}
          >
            <Button type="default">Esslink</Button>

            <Button type="default">Trace Frame</Button>

            <Button type="default">To VisioOffice</Button>

            <Button type="default">From VisioOffice</Button>

            <Button type="default">Adv Progressive Param</Button>

            <Button type="default">Electronic Order</Button>

            <Button type="default">Email Log</Button>

            <Button type="primary">Save</Button>
          </Row>
        </StyledForm>
      </Card>
    </div>
  );
};

export default SpectacleDetailsPage;
