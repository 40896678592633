import React, { useState } from "react";
import {
  Card,
  Form,
  DatePicker,
  Radio,
  Button,
  Table,
  Divider,
  Row,
  Col,
  InputNumber,
  Checkbox,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { Dayjs } from "dayjs";

interface SummarySaleData {
  key: string;
  dispenser: string;
  le100: number;
  le200: number;
  le300: number;
  le400: number;
  le500: number;
  gt500: number;
  total: number;
}

const SpectacleSummarySaleReport: React.FC = () => {
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns: ColumnsType<SummarySaleData> = [
    { title: "Dispenser", dataIndex: "dispenser", key: "dispenser" },
    { title: "<= $100", dataIndex: "le100", key: "le100" },
    { title: "<= $200", dataIndex: "le200", key: "le200" },
    { title: "<= $300", dataIndex: "le300", key: "le300" },
    { title: "<= $400", dataIndex: "le400", key: "le400" },
    { title: "<= $500", dataIndex: "le500", key: "le500" },
    { title: "> $500", dataIndex: "gt500", key: "gt500" },
    { title: "Total", dataIndex: "total", key: "total" },
  ];

  const dataSource: SummarySaleData[] = [
    {
      key: "1",
      dispenser: "Ms. Sven Lee",
      le100: 5,
      le200: 6,
      le300: 7,
      le400: 4,
      le500: 3,
      gt500: 2,
      total: 27,
    },
    {
      key: "2",
      dispenser: "Mr Viet Lee",
      le100: 5,
      le200: 6,
      le300: 7,
      le400: 4,
      le500: 3,
      gt500: 2,
      total: 27,
    },
    {
      key: "3",
      dispenser: "Miss Aloesna Lee",
      le100: 5,
      le200: 6,
      le300: 7,
      le400: 4,
      le500: 3,
      gt500: 2,
      total: 27,
    },
  ];

  const footerData = {
    le100: 15,
    le200: 18,
    le300: 21,
    le400: 12,
    le500: 9,
    gt500: 6,
    total: 81,
  };

  return (
    <Card style={{ margin: 24, borderRadius: 4, boxShadow: "0px 8px 40px -10px #00000014" }}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Report From Date" name="startDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Report To Date" name="endDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>

          {[1, 2, 3, 4, 5].map((num) => (
            <Col span={12}>
              <Form.Item
                key={num}
                label={`Spectacle Price Range ${num}$`}
                name={`priceRange${num}`}
                style={{ marginBottom: 12 }}
              >
                <InputNumber style={{ width: "100%" }} placeholder={`Enter price range ${num}$`} />
              </Form.Item>
            </Col>
          ))}

          <Col span={24}>
            <Form.Item name="includeJobs" valuePropName="checked">
              <Checkbox>Only Include Job with Frames & Lenses</Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Report Destination" name="destination" initialValue="screen">
              <Radio.Group>
                <Radio value="screen">To Screen</Radio>
                <Radio value="printer">To Printer</Radio>
                <Radio value="printerPrompt">To Printer with Prompt</Radio>
                <Radio value="excel">To Excel Spreadsheet</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Button
            onClick={() => {
              form.resetFields();
              setShowReport(false);
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={handleGenerateReport}>
            Generate Report
          </Button>
        </Row>
      </Form>

      {showReport && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Spectacle - Summary Sale Report From 10-10-2024 To 12-12-2024
          </Divider>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
            scroll={{ x: 1000 }}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{footerData.le100}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{footerData.le200}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{footerData.le300}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{footerData.le400}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{footerData.le500}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>{footerData.gt500}</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>{footerData.total}</Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </>
      )}
    </Card>
  );
};

export default SpectacleSummarySaleReport;
