import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Menu,
  Radio,
  Row,
  Table,
  Select,
  Input,
} from "antd";

const FrameReport: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState<
    "frameDetailUsage" | "frameSummaryUsage" | "frameSummaryStock" | "frameAgeing"
  >("frameDetailUsage");
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
    form.resetFields();
    setShowReport(false);
  };

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // Frame Detail Usage Report Configuration
  const frameDetailUsageColumns: any = [
    { title: "Frame Code", dataIndex: "frameCode", key: "frameCode" },
    { title: "Frame Description", dataIndex: "frameDescription", key: "frameDescription" },
    { title: "Usage Count", dataIndex: "usageCount", key: "usageCount" },
    {
      title: "Total Price $",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (value: number) => value.toFixed(2),
    },
  ];

  const frameDetailUsageData: any = [
    { key: "1", frameCode: "F001", frameDescription: "Frame A", usageCount: 10, totalPrice: 500.0 },
    { key: "2", frameCode: "F002", frameDescription: "Frame B", usageCount: 5, totalPrice: 300.0 },
  ];

  // Frame Summary Usage Report Configuration
  const frameSummaryUsageColumns: any = [
    { title: "Frame Type", dataIndex: "frameType", key: "frameType" },
    { title: "Total Usage Count", dataIndex: "totalUsageCount", key: "totalUsageCount" },
    {
      title: "Total Price $",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (value: number) => value.toFixed(2),
    },
  ];

  const frameSummaryUsageData: any = [
    { key: "1", frameType: "Type A", totalUsageCount: 15, totalPrice: 800.0 },
    { key: "2", frameType: "Type B", totalUsageCount: 8, totalPrice: 450.0 },
  ];

  // Frame Summary Stock Report Configuration
  const frameSummaryStockColumns: any = [
    { title: "Frame Code", dataIndex: "frameCode", key: "frameCode" },
    { title: "Frame Description", dataIndex: "frameDescription", key: "frameDescription" },
    { title: "Stock Count", dataIndex: "stockCount", key: "stockCount" },
    {
      title: "Total Value $",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (value: number) => value.toFixed(2),
    },
  ];

  const frameSummaryStockData: any = [
    {
      key: "1",
      frameCode: "F001",
      frameDescription: "Frame A",
      stockCount: 20,
      totalValue: 1000.0,
    },
    { key: "2", frameCode: "F002", frameDescription: "Frame B", stockCount: 10, totalValue: 600.0 },
  ];

  // Frame Ageing Report Configuration
  const frameAgeingColumns: any = [
    { title: "Frame Code", dataIndex: "frameCode", key: "frameCode" },
    { title: "Frame Description", dataIndex: "frameDescription", key: "frameDescription" },
    { title: "Age (Days)", dataIndex: "age", key: "age" },
    { title: "Stock Count", dataIndex: "stockCount", key: "stockCount" },
  ];

  const frameAgeingData: any = [
    { key: "1", frameCode: "F001", frameDescription: "Frame A", age: 120, stockCount: 15 },
    { key: "2", frameCode: "F002", frameDescription: "Frame B", age: 90, stockCount: 8 },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="frameDetailUsage"
              style={
                selectedKey === "frameDetailUsage"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame-Detail Usage Report
            </Menu.Item>
            <Menu.Item
              key="frameSummaryUsage"
              style={
                selectedKey === "frameSummaryUsage"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame-Summary Usage Report
            </Menu.Item>
            <Menu.Item
              key="frameSummaryStock"
              style={
                selectedKey === "frameSummaryStock"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Frame-Summary Stock Report
            </Menu.Item>
            <Menu.Item
              key="frameAgeing"
              style={
                selectedKey === "frameAgeing" ? { backgroundColor: "#18B6DA", color: "#fff" } : {}
              }
            >
              Frame-Ageing Report
            </Menu.Item>
          </Menu>
        </Card>
      </Col>

      <Col span={16}>
        <Card>
          <Divider orientation="left" orientationMargin="0">
            {selectedKey === "frameDetailUsage"
              ? "Frame-Detail Usage Report"
              : selectedKey === "frameSummaryUsage"
              ? "Frame-Summary Usage Report"
              : selectedKey === "frameSummaryStock"
              ? "Frame-Summary Stock Report"
              : "Frame-Ageing Report"}
          </Divider>

          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Supplier Code" name="supplierCode">
                  <Select placeholder="Select Supplier Code">
                    <Select.Option value="S001">S001</Select.Option>
                    <Select.Option value="S002">S002</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Brand" name="brand">
                  <Input placeholder="Enter Brand" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Frame Type" name="frameType">
                  <Input placeholder="Enter Frame Type" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Frame Group" name="frameGroup">
                  <Select placeholder="Select Frame Group">
                    <Select.Option value="Group A">Group A</Select.Option>
                    <Select.Option value="Group B">Group B</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Frame Status" name="frameStatus">
                  <Input placeholder="Enter Frame Status" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Location" name="location">
                  <Input placeholder="Enter Location" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Sort By" name="sortBy">
                  <Radio.Group>
                    <Radio value="brand">Brand and Model</Radio>
                    <Radio value="grossMargin">Gross Margin</Radio>
                    <Radio value="numbersSold">Numbers Sold</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">Screen</Radio>
                    <Radio value="printer">Printer</Radio>
                    <Radio value="printerPrompt">Printer with Prompt</Radio>
                    <Radio value="excel">Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }} />
              <Table
                columns={
                  selectedKey === "frameDetailUsage"
                    ? frameDetailUsageColumns
                    : selectedKey === "frameSummaryUsage"
                    ? frameSummaryUsageColumns
                    : selectedKey === "frameSummaryStock"
                    ? frameSummaryStockColumns
                    : frameAgeingColumns
                }
                dataSource={
                  selectedKey === "frameDetailUsage"
                    ? frameDetailUsageData
                    : selectedKey === "frameSummaryUsage"
                    ? frameSummaryUsageData
                    : selectedKey === "frameSummaryStock"
                    ? frameSummaryStockData
                    : frameAgeingData
                }
                pagination={false}
                bordered
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default FrameReport;
