export const BASE_PATHS = {
  DASHBOARD: "/dashboard",
  APPOINTMENT: "/appointment",
  HELP_SUPPORT: "/help-support",
  TICKET_TYPES: "/ticket-types",
};

export const AUTH_ROUTES = {
  LOGIN: "/signin",
  REGISTER: "/signup",
  RESET_PASSWORD: "/resetpassword",
  CHANGE_PASSWORD: "/changepassword",
};

export const PATIENT_RECORDS_ROUTES = {
  BASE: "/patient-records",
  CREATE: "/patient-records/create",
  UPDATE: "/patient-records/update/:id",
  DETAIL: "/patient-records/details/:id",
};

export const SUBSCRIPTION_ROUTES = {
  BASE: "/subscription",
  CREATE: "/subscription/create",
  UPDATE: "/subscription/update/:id",
  DETAILS: "/subscription/details/:id",
};

export const USER_ROUTES = {
  BASE: "/user-management",
  CREATE: "/user-management/create",
  UPDATE: "/user-management/update/:id",
  DETAILS: "/user-management/details/:id",
};

export const INVENTORY_ROUTES = {
  ADJUSTMENT_REPORT: "/inventory/adjustment-report",
  INVOICE_REPORT: "/inventory/invoice-report",
  FRAME_STOCK_REPORT: "/inventory/frame-stock-report",
  SUNDRY_STOCK_REPORT: "/inventory/sundry-stock-report",
  FRAME_APPROVAL: "/inventory/frame-approval",
  FRAME_ORDERING_FROM_USAGE: "/inventory/frame-ordering-from-usage",
  FRAME_BARCODE_BY_PURCHASE_DATE: "/inventory/frame-barcode-by-purchase-date-report",
  LENS_BARCODE_REPORT: "/inventory/lens-barcode-report",
  LENS_PRICE_LIST_REPORT: "/inventory/lens-price-list-report",
  FRAME_STOCKTAKE: "/inventory/frame-stocktake-by-barcode",

  FRAME: {
    BASE: "/inventory/frame",
    CREATE: "/inventory/frame/create",
    UPDATE: `/inventory/frame/update/:id`,
    DETAILS: `/inventory/frame/details/:id`,
  },
  SUNDRY: {
    BASE: "/inventory/sundry",
    CREATE: "/inventory/sundry/create",
    UPDATE: `/inventory/sundry/update/:id`,
    DETAILS: `/inventory/sundry/details/:id`,
  },
};

export const SUPPORT_ROUTES = {
  SUPPORT_TICKET_TYPE: {
    BASE: "/master-setup/support-ticket-type",
    CREATE: "/master-setup/support-ticket-type/create",
    UPDATE: `/master-setup/support-ticket-type/update/:id`,
    DETAILS: `/master-setup/support-ticket-type/details/:id`,
  },
};

export const FINANCIAL_ROUTES = {
  PETTY_CASH: {
    BASE: "/financial/petty-cash",
  },
  BANKING: {
    BASE: "/financial/banking",
  },
  BULK_BILLING_ACCOUNTS: {
    BASE: "/financial/bulk-billing-accounts",
  },
  BULK_BILLING_PAYMENT: {
    BASE: "/financial/bulk-billing-payment",
    DETAILS: "/financial/bulk-billing-payment-report/:id",
  },
  BULK_BILLING_PAYMENT_MEDICARE: {
    BASE: "/financial/bulk-billing-payment-medicare",
  },
  OUTSTANDING_BULK_BILLING_ACCOUNTS: {
    BASE: "/financial/bulk-billing-outstanding-accounts",
  },
  PETTY_CASH_CODE: {
    BASE: "/financial/petty-cash-code",
  },
  PETTY_CASH_ENTRY: {
    BASE: "/financial/petty-cash-entry",
  },
  PETTY_CASH_REPORTS: {
    BASE: "/financial/petty-cash-reports",
  },
  SUPPLIER_INVOICE_CREDIT: {
    BASE: "/financial/supplier-invoice-credit",
  },
  SUPPLIER_INVOICE_CREDIT_REPORT: {
    BASE: "/financial/supplier-invoice-credit-report",
  },
  SUPPLIER_INVOICE_CREDIT_SUMMARY_REPORT: {
    BASE: "/financial/supplier-invoice-credit-summary-report",
  },
  EXPENSE_PAYMENT: {
    BASE: "/financial/expense-payment",
  },
  EXPENSE_PAYMENT_REPORTS: {
    BASE: "/financial/expense-payment-report",
  },
  BANKING_RECONCILIATION: {
    BASE: "/financial/banking-reconciliation",
  },
  BILLING_ITEM_CASH_SALE_REPORT: {
    BASE: "/financial/billing-item-cash-sale-report",
  },
  BILLING_SUMMARY_YEARLY_GRAPH: {
    BASE: "/financial/billing-reports-summary-yearly-graph",
  },
  DETAIL_BILLING_ITEM_REPORT_BY_PATIENT: {
    BASE: "/financial/billing-reports/detail-item-by-patient",
  },
  BILLING_ITEM_REPORT_BY_PROVIDER: {
    BASE: "/financial/billing-item-by-provider-report",
  },
  SSF_BILLING_ITEM_SUMMARY: {
    BASE: "/financial/billing-reports-ssf-item-summary",
  },
  SSF_BILLING_ITEM_SUMMARY_BY_PROVIDER: {
    BASE: "/financial/billing-reports/ssf-item-summary-by-provider",
  },
  BILLING_SEARCH_BY_ITEM_NUMBER: {
    BASE: "/financial/billing-serach-from-item-number",
  },
  PAYMENT_SUMMARY: {
    BASE: "/financial/payment-summary",
  },
  DELETED_BILLING_PAYMENT_REPORT: {
    BASE: "/financial/deleted-billing-payment-report",
  },
  DETAIL_PAYMENT_REPORT: {
    BASE: "/financial/detail-payment-report",
  },
  BILLING_SUMMARY_REPORT: {
    BASE: "/financial/billing-summary-report",
  },
  REVERSE_NEGATIVE_BILLING_ITEM_REPORT: {
    BASE: "/financial/reverse-negative-billing-item-report",
  },
  CONSULTATION_REPORT_WITHOUT_BILLING: {
    BASE: "/financial/consultation-report-without-billing",
  },
  BILLING_SUMMARY_BY_GROUP_REPORT: {
    BASE: "/financial/billing-summary-by-group-report",
  },
};

export const STATISTICS_ROUTES = {
  CONSULTATION_ITEM_REPORT: {
    BASE: "/statistics/consultation-item-report",
  },
  PATIENT_HISTORY_SUMMARY: {
    BASE: "/statistics/patient-history-summary",
  },
  PATIENT_OLD_NEW_REPORT: {
    BASE: "/statistics/patient-old-new-report",
  },
  PATIENT_REPORT_BY_SUBURB: {
    BASE: "/statistics/patient-report-by-suburb",
  },
  RECALL_STATISTICS: {
    BASE: "/statistics/recall-statistics",
  },
  SPECTACLE_PRESCRIPTION_REPORT: {
    BASE: "/statistics/spectacle-prescription-report",
  },
  KPI_REPORT: {
    BASE: "/statistics/kpi-report",
  },
  THERAPEUTIC_PRESCRIPTION_SUMMARY: {
    BASE: "/statistics/therapeutic-prescription-summary",
  },
  FRAME_DETAILS_USAGE_REPORT: {
    BASE: "/statistics/frame-details-usage-report", // New frame details usage report route
  },
  FRAME_STOCK_TURN_REPORT_BY_BRAND: {
    BASE: "/statistics/frame-stock-turn-report-by-brand", // New route added here
  },
  SPECTACLE_EXPORT_JOB: {
    BASE: "/statistics/spectacle-export-job", // New route for spectacle export job
  },
  SPECTACLE_FRAME_REPORT: {
    BASE: "/statistics/spectacle-frame-report", // New route for spectacle frame report
  },
  SPECTACLE_LENS_TYPE_REPORT: {
    BASE: "/statistics/spectacle-lens-type-report", // New route for spectacle lens type report
  },
  SPECTACLE_LENS_DETAILS_USAGE_REPORT: {
    BASE: "/statistics/spectacle-lens-details-usage-report", // New route for spectacle lens details usage report
  },
  SPECTACLE_ANALYSIS_BY_PRESCRIBER: {
    BASE: "/statistics/spectacle-analysis-by-prescriber", // New route for spectacle analysis by prescriber
  },
  SPECTACLE_ANALYSIS_BY_DISPENSER: {
    BASE: "/statistics/spectacle-analysis-by-dispenser", // New route for spectacle analysis by dispenser
  },
  SPECTACLE_PROFIT_LOSS_REPORT: {
    BASE: "/statistics/spectacle-profit-loss-report", // New route for spectacle profit loss report
  },
  SPECTACLE_LENS_EXTRA_REPORT: {
    BASE: "/statistics/spectacle-lens-extra-report", // New route for spectacle lens extra report
  },
  SPECTACLE_SUMMARY_SALE_REPORT: {
    BASE: "/statistics/spectacle-summary-sale-report", // New route for spectacle summary sale report
  },
  CONTACT_LENS_EXPORT_JOB: {
    BASE: "/statistics/contact-lens-export-job", // New route for contact lens export job
  },
  CONTACT_LENS_USAGE_REPORT_WITH_PRICES: {
    BASE: "/statistics/contact-lens-usage-report-with-prices", // New route for contact lens usage report with prices
  },
  CONTACT_LENS_USAGE_REPORT_WITH_PRICES_BY_PRESCRIBER: {
    BASE: "/statistics/contact-lens-usage-report-with-prices-by-prescriber", // New route for contact lens usage report with prices by prescriber
  },
  CONTACT_LENS_USAGE_REPORT_WITH_RX: {
    BASE: "/statistics/contact-lens-usage-report-with-rx", // New route for contact lens usage report with Rx
  },
  SUNDRIES_USAGE_REPORT: {
    BASE: "/statistics/sundries-usage-report", // New route for sundries usage report
  },
  TRANSITION_USAGE_SUMMARY: {
    BASE: "/statistics/transition-usage-summary",
  },
  PRACTICE_SUMMARY_WEEKLY_REPORT: {
    BASE: "/statistics/practice-summary-weekly-report",
  },
  PRACTICE_SUMMARY_REPORT: {
    BASE: "/statistics/practice-summary-report",
  },
  FRAME_REPORT: {
    BASE: "/statistics/frame-report",
  },
};

export const MASTER_SETUP_ROUTES = {
  SUPPLIER: {
    BASE: "/master-setup/supplier",
    CREATE: "/master-setup/supplier/create",
    UPDATE: `/master-setup/supplier/update/:id`,
    DETAILS: `/master-setup/supplier/details/:id`,
  },

  PAYMENT_TYPE: {
    BASE: "/master-setup/payment-type",
    CREATE: "/master-setup/payment-type/create",
    UPDATE: `/master-setup/payment-type/update/:id`,
    DETAILS: `/master-setup/payment-type/details/:id`,
  },

  LENS_CODE: {
    BASE: "/master-setup/lens-code",
    CREATE: "/master-setup/lens-code/create",
    UPDATE: `/master-setup/lens-code/update/:id`,
    DETAILS: `/master-setup/lens-code/details/:id`,
  },

  FRAME_GROUP: {
    BASE: "/master-setup/frame-group",
    CREATE: "/master-setup/frame-group/create",
    UPDATE: `/master-setup/frame-group/update/:id`,
    DETAILS: `/master-setup/frame-group/details/:id`,
  },
  FRAME_TYPE: {
    BASE: "/master-setup/frame-type",
    CREATE: "/master-setup/frame-type/create",
    UPDATE: "/master-setup/frame-type/update/:id",
    DETAILS: "/master-setup/frame-type/details/:id",
  },
  WORKING_HOURS: {
    BASE: "/master-setup/working-hours",
    CREATE: "/master-setup/working-hours/create",
    UPDATE: "/master-setup/working-hours/update/:id",
    DETAILS: "/master-setup/working-hours/details/:id",
  },
  APPOINTMENT_TYPE: {
    BASE: "/master-setup/appointment-type",
    CREATE: "/master-setup/appointment-type/create",
    UPDATE: "/master-setup/appointment-type/update/:id",
    DETAILS: "/master-setup/appointment-type/details/:id",
  },
  PRACTITIONER_SETUP: {
    BASE: "/master-setup/practitioner-setup",
    CREATE: "/master-setup/practitioner-setup/create",
    UPDATE: "/master-setup/practitioner-setup/update/:id",
    DETAILS: "/master-setup/practitioner-setup/details/:id",
  },
  EMAIL_TEMPLATE: {
    BASE: "/master-setup/email-template-setup",
    CREATE: "/master-setup/email-template-setup/create",
    UPDATE: "/master-setup/email-template/update/:id",
    DETAILS: "/master-setup/email-template/details/:id",
  },
  SMS_TEMPLATE: {
    BASE: "/master-setup/sms-template-setup",
    CREATE: "/master-setup/sms-template-setup/create",
    UPDATE: "/master-setup/sms-template/update/:id",
    DETAILS: "/master-setup/sms-template/details/:id",
  },
};

export const CONSULTATION_ROUTES = {
  BASE: "/consultation",
  DETAIL: (id: string) => `/consultation/${id}`,
};

export const Fallback_ROUTES = {
  NOT_FOUND: "*",
};

export const ROUTES = {
  BASE: BASE_PATHS,
  INVENTORY: INVENTORY_ROUTES,
  MASTER_SETUP: MASTER_SETUP_ROUTES,
  SUPPORT: SUPPORT_ROUTES,
  PATIENT_RECORDS: PATIENT_RECORDS_ROUTES,
  SUBSCRIPTION: SUBSCRIPTION_ROUTES,
  USER: USER_ROUTES,
  NOTFOUND: Fallback_ROUTES.NOT_FOUND,
  AUTH_ROUTES: AUTH_ROUTES,
  DASHBOARD: BASE_PATHS.DASHBOARD,
  APPOINTMENT: BASE_PATHS.APPOINTMENT,
  CONSULTATION_ROUTES: CONSULTATION_ROUTES,
  HELP_AND_SUPPORT: BASE_PATHS.HELP_SUPPORT,
  TICKET_TYPES: BASE_PATHS.TICKET_TYPES,
  CONSULTATION: CONSULTATION_ROUTES,
  FINANCIAL_ROUTES: FINANCIAL_ROUTES,
  STATISTICS_ROUTES: STATISTICS_ROUTES,
};
