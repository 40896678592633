import React, { useState } from "react";
import { DatePicker, Button, Card, Form, Row, Col, Radio, Input, Table, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import styled from "@emotion/styled";

const { RangePicker } = DatePicker;

// Styled components
const Container = styled.div`
  padding: 20px;
`;

const ReportSection = styled(Card)`
  margin-top: 20px;
`;

const FrameDetailUsageReport: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleGenerateReport = async () => {
    const values = await form.validateFields();
    setShowReport(true);
  };

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  // Sample report data
  const frameUsageData = [
    { key: "1", attribute: "Total Frames Sold", value: "450" },
    { key: "2", attribute: "Top Selling Brand", value: "Ray-Ban" },
    { key: "3", attribute: "Most Popular Frame Type", value: "Aviator" },
    { key: "4", attribute: "Average Gross Margin", value: "42%" },
    { key: "5", attribute: "Total Inventory Value", value: "$125,000" },
  ];

  const columns = [
    {
      title: "Frame Attribute",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <Container>
      <Card>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="End Date" name="endDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Supplier Code" name="supplierCode">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Brand" name="brand">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Frame Type" name="frameType">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Frame Group" name="frameGroup">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Frame Status" name="frameStatus">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Location" name="location">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Sort By" name="sortBy">
                <Checkbox.Group>
                  <Checkbox value="brandModel">Brand & Model</Checkbox>

                  <Checkbox value="grossMargin">Gross Margin (Profit/Changes)</Checkbox>

                  <Checkbox value="numbersSold">Numbers Sold</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="excel">To Excel Spreadsheet</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Form.Item>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>

      {showReport && (
        <ReportSection title="Frame Usage Report">
          <Table
            dataSource={frameUsageData}
            columns={columns}
            pagination={false}
            bordered
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total Records</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{frameUsageData.length}</Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </ReportSection>
      )}
    </Container>
  );
};

export default FrameDetailUsageReport;
