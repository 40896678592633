import React, { useState } from "react";
import { DatePicker, Button, Card, Form, Row, Col, Radio, Input, Table } from "antd";
import styled from "@emotion/styled";

// Styled components
const Container = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const ReportSection = styled(Card)`
  margin-top: 20px;
`;

const FrameStockTurnReportByBrand: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleGenerateReport = async () => {
    const values = await form.validateFields();
    setShowReport(true);
  };

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  // Sample report data matching the image structure
  const stockTurnData = [
    {
      key: "1",
      brand: "AOA",
      supplier: "ZRH",
      qty1: "-",
      sales1: "-",
      qty2: "-",
      sales2: "-",
      qty3: 121,
      sales3: "1253.00",
      totalQty: 121,
      totalSales: "1253.00",
      stockTurn: "2.503x",
    },
    {
      key: "2",
      brand: "Bill Bass",
      supplier: "-",
      qty1: 21,
      sales1: "1125.00",
      qty2: 56,
      sales2: "1852.00",
      qty3: 77,
      sales3: "2365.00",
      totalQty: 148,
      totalSales: "5236.00",
      stockTurn: "4.25x",
    },
    {
      key: "3",
      brand: "Boss",
      supplier: "SAF",
      qty1: 88,
      sales1: "5563.00",
      qty2: 50,
      sales2: "1563.00",
      qty3: 125,
      sales3: "9563.00",
      totalQty: 256,
      totalSales: "1758.00",
      stockTurn: "8.63x",
    },
  ];

  const columns: any = [
    { title: "Brand", dataIndex: "brand", key: "brand", fixed: "left" },
    { title: "Supplier", dataIndex: "supplier", key: "supplier" },
    {
      title: "Qty",
      dataIndex: "qty1",
      key: "qty1",
      render: (text: any) => (text === "-" ? "-" : Number(text).toLocaleString()),
    },
    {
      title: "Sales $ (Inc. GST)",
      dataIndex: "sales1",
      key: "sales1",
      render: (text: any) => (text === "-" ? "-" : `$${Number(text).toLocaleString()}`),
    },
    {
      title: "Qty",
      dataIndex: "qty2",
      key: "qty2",
      render: (text: any) => (text === "-" ? "-" : Number(text).toLocaleString()),
    },
    {
      title: "Sales $ (Inc. GST)",
      dataIndex: "sales2",
      key: "sales2",
      render: (text: any) => (text === "-" ? "-" : `$${Number(text).toLocaleString()}`),
    },
    {
      title: "Qty",
      dataIndex: "qty3",
      key: "qty3",
      render: (text: any) => (text === "-" ? "-" : Number(text).toLocaleString()),
    },
    {
      title: "Sales $ (Inc. GST)",
      dataIndex: "sales3",
      key: "sales3",
      render: (text: any) => (text === "-" ? "-" : `$${Number(text).toLocaleString()}`),
    },
    {
      title: "Total Qty",
      dataIndex: "totalQty",
      key: "totalQty",
      render: (text: any) => Number(text).toLocaleString(),
    },
    {
      title: "Total $ (Inc. GST)",
      dataIndex: "totalSales",
      key: "totalSales",
      render: (text: any) => `$${Number(text).toLocaleString()}`,
    },
    {
      title: "Stock Turn",
      dataIndex: "stockTurn",
      key: "stockTurn",
      render: (text: any) => <strong>{text}</strong>,
    },
  ];

  return (
    <Container>
      <Card>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Report From Date" name="fromDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Report To Date" name="toDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Supplier" name="supplier">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="destination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Form.Item>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>

      {showReport && (
        <ReportSection
          title={`Frame Stock Turn Report By Brand From ${
            form.getFieldValue("fromDate")?.format("DD-MM-YYYY") || "10-10-2024"
          } To ${form.getFieldValue("toDate")?.format("DD-MM-YYYY") || "12-12-2024"}`}
        >
          <Table
            dataSource={stockTurnData}
            columns={columns}
            pagination={false}
            bordered
            scroll={{ x: 1500 }}
            summary={() => (
              <Table.Summary.Row>
                {columns.map((_: any, index: any) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {index === columns.length - 1 && <strong>Grand Total</strong>}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            )}
          />
        </ReportSection>
      )}
    </Container>
  );
};

export default FrameStockTurnReportByBrand;
