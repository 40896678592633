import React, { useState } from "react";
import { Card, Row, Col, Menu, Divider, Button, Table, Form, DatePicker, Radio } from "antd";

const SpectacleLensTypeReport: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("lensTypeSummaryByGrouping");
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
    form.resetFields();
    setShowReport(false);
  };

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const lensTypeColumns: any = [
    { title: "Lens Type", dataIndex: "lensType", key: "lensType" },
    { title: "Total No. of Lenses", dataIndex: "totalLenses", key: "totalLenses" },
    { title: "No. of Stock Lenses", dataIndex: "stockLenses", key: "stockLenses" },
    { title: "No. of Grind Lenses", dataIndex: "grindLenses", key: "grindLenses" },
    {
      title: "Total Exc. Tax Price of Lenses $",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (value: number) => value.toFixed(2),
    },
    {
      title: "Avg Exc. Tax Price per Lens $",
      dataIndex: "avgPrice",
      key: "avgPrice",
      render: (value: number) => value.toFixed(2),
    },
  ];

  const lensTypeData: any = [
    {
      key: "1",
      lensType: "Single Vision",
      totalLenses: 100,
      stockLenses: 60,
      grindLenses: 40,
      totalPrice: 5000.0,
      avgPrice: 50.0,
    },
    {
      key: "2",
      lensType: "Progressive",
      totalLenses: 50,
      stockLenses: 20,
      grindLenses: 30,
      totalPrice: 7500.0,
      avgPrice: 150.0,
    },
    {
      key: "3",
      lensType: "Bifocal",
      totalLenses: 25,
      stockLenses: 15,
      grindLenses: 10,
      totalPrice: 3000.0,
      avgPrice: 120.0,
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item
              key="lensTypeSummaryByGrouping"
              style={
                selectedKey === "lensTypeSummaryByGrouping"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Spectacle-Lens Type Summary by Grouping
            </Menu.Item>
            <Menu.Item
              key="lensTypeSummaryByStockGrind"
              style={
                selectedKey === "lensTypeSummaryByStockGrind"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Spectacle-Lens Type Summary by Stock/Grind
            </Menu.Item>
            <Menu.Item
              key="lensTypeSummaryByPrescriber"
              style={
                selectedKey === "lensTypeSummaryByPrescriber"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Spectacle-Lens Type Summary by Prescriber
            </Menu.Item>
            <Menu.Item
              key="lensTypeSummaryByDispenser"
              style={
                selectedKey === "lensTypeSummaryByDispenser"
                  ? { backgroundColor: "#18B6DA", color: "#fff" }
                  : {}
              }
            >
              Spectacle-Lens Type by Dispenser
            </Menu.Item>
          </Menu>
        </Card>
      </Col>

      <Col span={16}>
        <Card style={{ height: "calc(100vh - 138px)", overflowY: "auto" }}>
          <Divider orientation="left" orientationMargin="0">
            Spectacle Lens Type Report -{" "}
            {selectedKey
              .replace(/lensTypeSummaryBy|lensTypeBy/, "")
              .replace(/([A-Z])/g, " $1")
              .trim()}
          </Divider>

          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">Screen</Radio>
                    <Radio value="printer">Printer</Radio>
                    <Radio value="printerPrompt">Printer with Prompt</Radio>
                    <Radio value="excel">Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }} />
              <Table
                columns={lensTypeColumns}
                dataSource={lensTypeData}
                pagination={false}
                bordered
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SpectacleLensTypeReport;
