import React, { useState } from "react";
import { Card, Row, Col, Form, DatePicker, Button, Table, Typography, Input, Divider } from "antd";

const TransitionUsageSummary: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);
  const [reportDates, setReportDates] = useState({ from: "", to: "" });

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      const values = await form.validateFields();
      setReportDates({
        from: values.reportFrom.format("DD-MM-YYYY"),
        to: values.reportTo.format("DD-MM-YYYY"),
      });
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    {
      title: "No. of Half-Pair Lenses Sold",
      dataIndex: "lensType",
      key: "lensType",
      render: (text: string) => (
        <span style={{ fontWeight: text === "Total" ? 600 : 400 }}>{text}</span>
      ),
    },
    {
      title: "Transition",
      dataIndex: "transition",
      key: "transition",
      align: "right" as const,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right" as const,
    },
    {
      title: "Transition Market Share %",
      dataIndex: "marketShare",
      key: "marketShare",
      align: "right" as const,
    },
  ];

  const data = [
    { key: "1", lensType: "Single Vision", transition: 0, total: 0, marketShare: "0.00%" },
    { key: "2", lensType: "Bifocal", transition: 0, total: 0, marketShare: "0.00%" },
    { key: "3", lensType: "Progressive", transition: 0, total: 50, marketShare: "0.00%" },
    { key: "4", lensType: "Total", transition: 0, total: 50, marketShare: "0.00%", isTotal: true },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Report From"
                  name="reportFrom"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Report To"
                  name="reportTo"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Transition Email Address" name="transitionemailaddress">
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <div style={{ marginTop: 24 }}>
              <Divider orientation="left">
                Transition Usage Summary From {reportDates.from} To {reportDates.to}
              </Divider>

              <Typography.Text
                strong
                style={{
                  display: "block",
                  marginBottom: "1rem",
                  fontSize: "1rem",
                }}
              >
                Practice: Zoom_Optics-Liverpool
              </Typography.Text>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                components={{
                  body: {
                    cell: (props: any) => {
                      const record = props.children?.props?.record;
                      return (
                        <td
                          {...props}
                          style={{
                            ...props.style,
                            backgroundColor: record?.isTotal ? "#f0f0f0" : "inherit",
                            fontWeight: record?.isTotal ? 600 : "normal",
                            padding: "8px 16px",
                          }}
                        />
                      );
                    },
                  },
                }}
              />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TransitionUsageSummary;
