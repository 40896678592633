import React from "react";
import { Table, Input, Button, Space } from "antd";
import { EditOutlined, PrinterOutlined } from "@ant-design/icons"; // Import icons
import styled from "@emotion/styled";

const { Search } = Input;

// Styled components
const Container = styled.div`
  padding: 20px;
`;

const ActionBar = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

// Define types for the data
interface Prescription {
  key: string;
  sn: number;
  presNo: number;
  refNo: number;
  surname: string;
  givenName: string;
  rxDate: string;
  opium: string;
  expired: string;
  drug: string;
  eye: string;
}

// Sample data
const dataSource: Prescription[] = [
  {
    key: "1",
    sn: 1,
    presNo: 112,
    refNo: 1236,
    surname: "Rai",
    givenName: "Preeti",
    rxDate: "10/10/2024",
    opium: "Daniel Tu",
    expired: "-",
    drug: "-",
    eye: "-",
  },
  {
    key: "2",
    sn: 2,
    presNo: 113,
    refNo: 5623,
    surname: "Limbu",
    givenName: "Samar",
    rxDate: "15/10/2024",
    opium: "Daniel Tu",
    expired: "-",
    drug: "-",
    eye: "-",
  },
  {
    key: "3",
    sn: 3,
    presNo: 456,
    refNo: 1125,
    surname: "Shrestha",
    givenName: "Umesh",
    rxDate: "31/11/2024",
    opium: "Daniel Tu",
    expired: "-",
    drug: "-",
    eye: "-",
  },
];

// Define columns with proper types
const columns = [
  {
    title: "SN",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Pres No.",
    dataIndex: "presNo",
    key: "presNo",
  },
  {
    title: "Ref No.",
    dataIndex: "refNo",
    key: "refNo",
  },
  {
    title: "Surname",
    dataIndex: "surname",
    key: "surname",
  },
  {
    title: "Given Name",
    dataIndex: "givenName",
    key: "givenName",
  },
  {
    title: "Rx Date",
    dataIndex: "rxDate",
    key: "rxDate",
  },
  {
    title: "Opium",
    dataIndex: "opium",
    key: "opium",
  },
  {
    title: "Expired",
    dataIndex: "expired",
    key: "expired",
  },
  {
    title: "Drug",
    dataIndex: "drug",
    key: "drug",
  },
  {
    title: "Eye",
    dataIndex: "eye",
    key: "eye",
  },
];

const TherapeuticPrescriptionSummary: React.FC = () => {
  const handleSearch = (value: string) => {
    console.log("Search:", value);
    // Implement search functionality
  };

  const handlePrintByDate = () => {
    console.log("Print by date");
    // Implement print by date functionality
  };

  const handlePrintByDrug = () => {
    console.log("Print by drug");
    // Implement print by drug functionality
  };

  const handleEdit = (record: Prescription) => {
    console.log("Edit record:", record);
    // Implement edit functionality
  };

  return (
    <Container>
      <ActionBar>
        <Search placeholder="Search Ref No." onSearch={handleSearch} style={{ width: 200 }} />
        <Space>
          <Button icon={<PrinterOutlined />} onClick={handlePrintByDate}>
            Print Prescription Report By Date
          </Button>
          <Button icon={<PrinterOutlined />} onClick={handlePrintByDrug} type="primary">
            Print Prescription Report By Drug
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(null as unknown as Prescription)}
          >
            Edit
          </Button>
        </Space>
      </ActionBar>
      <Table dataSource={dataSource} columns={columns} />
    </Container>
  );
};

export default TherapeuticPrescriptionSummary;
