import React, { useState } from "react";
import { Card, Row, Col, Form, DatePicker, Button, Table, Divider, Radio } from "antd";

const ContactLensUsageReportWithPricesByPrescriber: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    { title: "Prescriber", dataIndex: "prescriber", key: "prescriber" },
    { title: "CL Code", dataIndex: "code", key: "code" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Qty", dataIndex: "qty", key: "qty", align: "right" as const },
    { title: "Cost $", dataIndex: "cost", key: "cost", align: "right" as const },
    { title: "RBP $", dataIndex: "rbp", key: "rbp", align: "right" as const },
    { title: "Gross Profit %", dataIndex: "profitPct", key: "profitPct", align: "center" as const },
    { title: "Profit $", dataIndex: "profit", key: "profit", align: "right" as const },
  ];

  const data = [
    // First prescriber group
    {
      key: "1",
      prescriber: "-",
      code: "AAAA",
      description: "Actuvi Oasis 1 Day 30 Packs",
      qty: 12,
      cost: 115.0,
      rbp: 115.0,
      profitPct: "50%",
      profit: 57.5,
    },
    {
      key: "2",
      prescriber: "-",
      code: "AAAAA",
      description: "Actuvi Oasis 1 Day 90 Packs",
      qty: 13,
      cost: 698.0,
      rbp: 698.0,
      profitPct: "60%",
      profit: 418.8,
    },
    {
      key: "3",
      prescriber: "-",
      code: "AAAAAA",
      description: "Actuvi Oasis 1 Day Moist 30 Packs",
      qty: 15,
      cost: 269.55,
      rbp: 269.55,
      profitPct: "70%",
      profit: 188.69,
    },
    {
      key: "4",
      type: "subtotal",
      prescriber: "Profit $",
      code: "450.00",
      qty: 40,
      cost: 1129.55,
      rbp: 1129.55,
      profitPct: "60%",
      className: "subtotal-row",
    },

    // Second prescriber group
    {
      key: "5",
      prescriber: "DT",
      code: "AAAA",
      description: "Actuvi Oasis 1 Day 30 Packs",
      qty: 12,
      cost: 115.0,
      rbp: 115.0,
      profitPct: "50%",
      profit: 57.5,
    },
    {
      key: "6",
      prescriber: "DT",
      code: "AAAAAA",
      description: "Actuvi Oasis 1 Day Moist 30 Packs",
      qty: 15,
      cost: 269.55,
      rbp: 269.55,
      profitPct: "70%",
      profit: 188.69,
    },
    {
      key: "7",
      type: "subtotal",
      prescriber: "Profit $",
      code: "450.00",
      qty: 27,
      cost: 384.55,
      rbp: 384.55,
      profitPct: "60%",
      className: "subtotal-row",
    },

    // Total row
    {
      key: "8",
      type: "total",
      prescriber: "Total Profit $",
      code: "900.00",
      qty: 67,
      cost: 1529.1,
      rbp: 1529.1,
      profitPct: "60%",
      className: "total-row",
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Report From"
                  name="reportFrom"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Report To"
                  name="reportTo"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">To Screen</Radio>
                    <Radio value="printer">To Printer</Radio>
                    <Radio value="printerPrompt">To Printer with Prompt</Radio>
                    <Radio value="excel">To Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }}>
                Contact Lens Usage Report With Prices By Prescriber From 10-10-2024 To 12-12-2024
              </Divider>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                rowClassName={(record) => record.className || ""}
                components={{
                  body: {
                    cell: (props: any) => (
                      <td
                        {...props}
                        style={{
                          ...props.style,
                          backgroundColor: props.className?.includes("total-row")
                            ? "#fafafa"
                            : "inherit",
                          fontWeight: props.className?.includes("total-row") ? 600 : "normal",
                        }}
                      />
                    ),
                  },
                }}
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ContactLensUsageReportWithPricesByPrescriber;
