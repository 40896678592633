/** @jsxImportSource @emotion/react */

import React, { useState, useRef } from "react";
import { Button, Card, Form, Input, Row, Col, Typography, Tag, Divider, Avatar } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { UserOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";

const cardTitleStyle = css`
  .ant-card-head {
    background-color: white !important; /* Force white background */
    padding: 16px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }
`;

const outerCardStyle = css`
  background-color: #f3f4f6;
`;

const AddEmailTemplate = () => {
  const [form] = Form.useForm();
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const quillRef = useRef<ReactQuill>(null);
  const parameters = ["Patient", "Doctor", "Appointment Date", "Bill Amount"];

  const handleAddParameter = (param: string) => {
    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const range = editor.getSelection();
    if (range) {
      editor.insertText(range.index, `{${param}}`);
    } else {
      editor.insertText(editor.getLength(), `{${param}}`);
    }
  };

  const handleSave = (values: any) => {
    console.log("Saving template:", { ...values, emailBody });
    // Add API call here
  };

  const handleCancel = () => {
    form.resetFields();
    setEmailBody("");
    setEmailSubject("");
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleSave}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Template Title"
              name="templateTitle"
              rules={[{ required: true, message: "Please enter template title" }]}
            >
              <Input placeholder="Enter template title" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin={0}>
          Template Details
        </Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email Subject"
              name="emailSubject"
              rules={[{ required: true, message: "Please enter email subject" }]}
            >
              <Input
                placeholder="Enter email subject"
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Email Body"
              name="emailBody"
              rules={[{ required: true, message: "Please enter email body" }]}
            >
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={emailBody}
                onChange={(value) => {
                  setEmailBody(value);
                  form.setFieldsValue({ emailBody: value });
                }}
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                }}
              />
            </Form.Item>

            <div style={{ marginBottom: 24 }}>
              <Typography.Text strong>Available Parameters:</Typography.Text>
              <div style={{ marginTop: 8 }}>
                {parameters.map((param) => (
                  <Tag
                    key={param}
                    onClick={() => handleAddParameter(param)}
                    color="blue"
                    style={{
                      cursor: "pointer",
                      marginBottom: 4,
                      padding: "4px 8px",
                    }}
                  >
                    {param}
                  </Tag>
                ))}
              </div>
            </div>
          </Col>

          <Col span={12}>
            <Card css={[cardTitleStyle, outerCardStyle]} title="Preview">
              <Card
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      padding: "16px 10px",
                    }}
                  >
                    <Avatar
                      size={40}
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#1677ff" }}
                    />

                    <div>
                      <Typography.Text strong style={{ fontSize: "16px" }}>
                        {emailSubject || "Subject"}
                      </Typography.Text>
                      <br />
                      <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
                        user@example.com
                      </Typography.Text>
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    marginTop: 8,
                  }}
                >
                  {emailBody ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: emailBody.replace(
                          /{([^}]+)}/g,
                          (match, param) =>
                            `<span style="
              background: #e6f7ff;
              border: 1px solid #91d5ff;
              border-radius: 2px;
              padding: 0 4px;
              margin: 0 2px;
            ">${param}</span>`
                        ),
                      }}
                    />
                  ) : (
                    <Typography.Text>Email Body</Typography.Text>
                  )}
                </div>
              </Card>
            </Card>
          </Col>
        </Row>
        <Divider />

        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save Template
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddEmailTemplate;
