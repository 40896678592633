/** @jsxImportSource @emotion/react */

import React, { useState, useRef } from "react";
import { Button, Card, Form, Input, Row, Col, Typography, Tag, Divider } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const messageContainerStyle = css`
  background-color: #e3f7fc;
  color: #333;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #a5d8f3;
  max-width: 400px;
  margin: 10px 0;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const MessageContainer = styled.div`
  ${messageContainerStyle}
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 14px;
`;

const cardTitleStyle = css`
  .ant-card-head {
    background-color: white !important; /* Force white background */
    padding: 16px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }
`;

const outerCardStyle = css`
  background-color: #f3f4f6;
`;

const AddSMSTemplate = () => {
  const [form] = Form.useForm();
  const [smsBody, setSmsBody] = useState("");
  const quillRef = useRef<ReactQuill>(null);
  const parameters = ["Patient Name", "Appointment Date", "Doctor Name", "Clinic Address"];

  const handleAddParameter = (param: string) => {
    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const range = editor.getSelection();
    if (range) {
      editor.insertText(range.index, `{${param}}`);
    } else {
      editor.insertText(editor.getLength(), `{${param}}`);
    }
  };

  const handleSave = (values: any) => {
    console.log("Saving SMS template:", { ...values, smsBody });
    // Add API call here
  };

  const handleCancel = () => {
    form.resetFields();
    setSmsBody("");
  };

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleSave}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Template Title"
              name="templateTitle"
              rules={[{ required: true, message: "Please enter template title" }]}
            >
              <Input placeholder="Enter template title" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" orientationMargin={0}>
          Template Details
        </Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="SMS Body"
              name="smsBody"
              rules={[{ required: true, message: "Please enter SMS body" }]}
            >
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={smsBody}
                onChange={(value) => {
                  setSmsBody(value);
                  form.setFieldsValue({ smsBody: value });
                }}
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                }}
              />
            </Form.Item>

            <div style={{ marginBottom: 24 }}>
              <Typography.Text strong>Available Parameters:</Typography.Text>
              <div style={{ marginTop: 8 }}>
                {parameters.map((param) => (
                  <Tag
                    key={param}
                    onClick={() => handleAddParameter(param)}
                    color="blue"
                    style={{
                      cursor: "pointer",
                      marginBottom: 4,
                      padding: "4px 8px",
                    }}
                  >
                    {param}
                  </Tag>
                ))}
              </div>
            </div>
          </Col>

          <Col span={12}>
            <Card css={[cardTitleStyle, outerCardStyle]} title="Preview">
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  marginTop: 8,
                }}
              >
                {smsBody ? (
                  <MessageContainer>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: smsBody.replace(
                          /{([^}]+)}/g,
                          (match, param) =>
                            `<span style="background: #e6f7ff; border: 1px solid #91d5ff; border-radius: 2px; padding: 0 4px; margin: 0 2px;">${param}</span>`
                        ),
                      }}
                    />
                  </MessageContainer>
                ) : (
                  <MessageContainer>
                    <Typography.Text>SMS Body</Typography.Text>
                  </MessageContainer>
                )}
              </div>
            </Card>
          </Col>
        </Row>
        <Divider />

        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save Template
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddSMSTemplate;
