import React, { useState } from "react";
import { Card, Form, DatePicker, Radio, Button, Table, Divider, Row, Col } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { Dayjs } from "dayjs";

interface ReportData {
  key: string;
  orderDate: string;
  jobNo: number;
  patientName: string;
  totalCost: number;
  gstCost: number;
  frameSale: number;
  lensSale: number;
  othersSale: number;
  gstSale: number;
  reasonForDiscount: string;
  frameBrand: string;
  rightLensType: string;
  leftLensType: string;
  grossProfit: number;
  grossMargin: string;
}

const SpectacleProfitLossReport: React.FC = () => {
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns: ColumnsType<ReportData> = [
    { title: "Order Date", dataIndex: "orderDate", key: "orderDate" },
    { title: "Job No.", dataIndex: "jobNo", key: "jobNo" },
    { title: "Patient Name", dataIndex: "patientName", key: "patientName" },
    {
      title: "Total Cost (Inc. GST)",
      dataIndex: "totalCost",
      key: "totalCost",
      render: (v) => v.toFixed(2),
    },
    {
      title: "GST of Total Cost",
      dataIndex: "gstCost",
      key: "gstCost",
      render: (v) => v.toFixed(2),
    },
    {
      title: "Frame Sale (Inc. GST)",
      dataIndex: "frameSale",
      key: "frameSale",
      render: (v) => v.toFixed(2),
    },
    {
      title: "Lens Sale (Inc. GST)",
      dataIndex: "lensSale",
      key: "lensSale",
      render: (v) => v.toFixed(2),
    },
    {
      title: "Others Sale (Inc. GST)",
      dataIndex: "othersSale",
      key: "othersSale",
      render: (v) => v.toFixed(2),
    },
    {
      title: "GST of Total Sale",
      dataIndex: "gstSale",
      key: "gstSale",
      render: (v) => v.toFixed(2),
    },
    { title: "Reason for Discount", dataIndex: "reasonForDiscount", key: "reasonForDiscount" },
    { title: "Frame Brand", dataIndex: "frameBrand", key: "frameBrand" },
    { title: "Right Lens Type", dataIndex: "rightLensType", key: "rightLensType" },
    { title: "Left Lens Type", dataIndex: "leftLensType", key: "leftLensType" },
    {
      title: "Gross Profit $ (Exc. Tax)",
      dataIndex: "grossProfit",
      key: "grossProfit",
      render: (v) => v.toFixed(2),
    },
    { title: "Gross Margin % (Profit/Sales)", dataIndex: "grossMargin", key: "grossMargin" },
  ];

  const sampleData: ReportData[] = [
    {
      key: "1",
      orderDate: "10/10/2024",
      jobNo: 24,
      patientName: "Shyam Maharjan",
      totalCost: 88.9,
      gstCost: 11,
      frameSale: 115,
      lensSale: 0,
      othersSale: 0,
      gstSale: 5.9,
      reasonForDiscount: "-",
      frameBrand: "Prada",
      rightLensType: "-",
      leftLensType: "-",
      grossProfit: 45.89,
      grossMargin: "45%",
    },
    {
      key: "2",
      orderDate: "25/10/2024",
      jobNo: 26,
      patientName: "Ram Bahadur",
      totalCost: 678,
      gstCost: 22,
      frameSale: 698,
      lensSale: 67,
      othersSale: 23.27,
      gstSale: 2.26,
      reasonForDiscount: "-",
      frameBrand: "Spencer",
      rightLensType: "A1",
      leftLensType: "A1",
      grossProfit: 77.9,
      grossMargin: "20%",
    },
    {
      key: "3",
      orderDate: "12/11/2024",
      jobNo: 27,
      patientName: "Shreya Maharjan",
      totalCost: 234.55,
      gstCost: 33,
      frameSale: 269.55,
      lensSale: 79,
      othersSale: 66.87,
      gstSale: 10.89,
      reasonForDiscount: "-",
      frameBrand: "Vague",
      rightLensType: "A3",
      leftLensType: "A3",
      grossProfit: 102.89,
      grossMargin: "22%",
    },
  ];

  return (
    <Card style={{ margin: 24, borderRadius: 4, boxShadow: "0px 8px 40px -10px #00000014" }}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End Date" name="endDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Report Destination" name="destination" initialValue="screen">
              <Radio.Group>
                <Radio value="screen">To Screen</Radio>
                <Radio value="printer">To Printer</Radio>
                <Radio value="printerPrompt">To Printer with Prompt</Radio>
                <Radio value="excel">To Excel Spreadsheet</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Button
            onClick={() => {
              form.resetFields();
              setShowReport(false);
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={handleGenerateReport}>
            Generate Report
          </Button>
        </Row>
      </Form>

      {showReport && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Spectacle Profit Loss Report
          </Divider>
          <Table
            columns={columns}
            dataSource={sampleData}
            pagination={false}
            bordered
            scroll={{ x: 100 }}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={13} index={2} />
                <Table.Summary.Cell index={3}>
                  <strong>
                    {sampleData.reduce((sum, item) => sum + item.grossProfit, 0).toFixed(2)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} />
              </Table.Summary.Row>
            )}
          />
        </>
      )}
    </Card>
  );
};

export default SpectacleProfitLossReport;
