import React, { useState } from "react";
import { Card, Row, Col, Form, DatePicker, Button, Table, Divider, Radio, Select } from "antd";

const ContactLensUsageReportWithRx: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);
  const [reportDates, setReportDates] = useState({ from: "", to: "" });

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      const values = await form.validateFields();
      setReportDates({
        from: values.reportFrom.format("DD-MM-YYYY"),
        to: values.reportTo.format("DD-MM-YYYY"),
      });
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    { title: "Rx", dataIndex: "rx", key: "rx" },
    { title: "Qty", dataIndex: "qty", key: "qty", align: "right" as const },
  ];

  const data = [
    { key: "1", rx: "> +8.0", qty: 12 },
    { key: "2", rx: "+8.0", qty: 13 },
    { key: "3", rx: "+7.75", qty: 15 },
    { key: "4", rx: "CL Total Lens", qty: 40, className: "total-row" },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Report From"
                  name="reportFrom"
                  rules={[{ required: true, message: "Please select start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Report To"
                  name="reportTo"
                  rules={[{ required: true, message: "Please select end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Supplier" name="supplier">
                  <Select style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Contact Lens Code" name="contactlenscode">
                  <Select style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">To Screen</Radio>
                    <Radio value="printer">To Printer</Radio>
                    <Radio value="printerPrompt">To Printer with Prompt</Radio>
                    <Radio value="excel">To Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <div style={{ marginTop: 24 }}>
              <Divider>
                Contact Lens Usage Report With Rx From {reportDates.from} To {reportDates.to}
              </Divider>

              <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                  <strong>Supplier:</strong> <span>-</span>
                </Col>
              </Row>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                rowClassName={(record) => record.className || ""}
                components={{
                  body: {
                    cell: (props: any) => (
                      <td
                        {...props}
                        style={{
                          ...props.style,
                          backgroundColor: props.className?.includes("total-row")
                            ? "#f0f0f0"
                            : "inherit",
                          fontWeight: props.className?.includes("total-row") ? 600 : "normal",
                        }}
                      />
                    ),
                  },
                }}
                showHeader={false}
              />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ContactLensUsageReportWithRx;
