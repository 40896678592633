import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, DatePicker, Button, Radio, Table } from "antd";
import dayjs from "dayjs";

const PracticeSummaryWeeklyReport: React.FC = () => {
  const [reportDate, setReportDate] = useState<string | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setReportDate(dayjs(values.reportDate).format("DD-MM-YYYY"));
  };

  const patientPaymentColumns = [
    {
      title: "Headings",
      dataIndex: "headings",
      key: "headings",
    },
    {
      title: "01/01/2024 - 01/07/2024",
      dataIndex: "week1",
      key: "week1",
    },
    {
      title: "01/08/2024 - 01/14/2024",
      dataIndex: "week2",
      key: "week2",
    },
    {
      title: "01/14/2024 - 01/21/2024",
      dataIndex: "week3",
      key: "week3",
    },
    {
      title: "01/21/2024 - 01/28/2024",
      dataIndex: "week4",
      key: "week4",
    },
    {
      title: "Total $",
      dataIndex: "total",
      key: "total",
    },
  ];

  const patientPaymentData = [
    {
      key: "1",
      headings: "Priv Consult",
      week1: "10.00",
      week2: "10.00",
      week3: "10.00",
      week4: "50.00",
      total: "80.00",
    },
    {
      key: "2",
      headings: "Contact Lenses",
      week1: "20.00",
      week2: "20.00",
      week3: "20.00",
      week4: "20.00",
      total: "80.00",
    },
    {
      key: "3",
      headings: "Spectacles",
      week1: "30.00",
      week2: "30.00",
      week3: "30.00",
      week4: "30.00",
      total: "120.00",
    },
    {
      key: "4",
      headings: "Sunglasses",
      week1: "40.00",
      week2: "40.00",
      week3: "40.00",
      week4: "40.00",
      total: "160.00",
    },
    {
      key: "5",
      headings: "Sundries",
      week1: "50.00",
      week2: "50.00",
      week3: "50.00",
      week4: "50.00",
      total: "200.00",
    },
    {
      key: "6",
      headings: "Refund",
      week1: "60.00",
      week2: "60.00",
      week3: "-",
      week4: "-",
      total: "120.00",
    },
    {
      key: "7",
      headings: "Payment - Misc",
      week1: "70.00",
      week2: "70.00",
      week3: "70.00",
      week4: "90.00",
      total: "300.00",
    },
    {
      key: "8",
      headings: "Bulk-Bill Payment",
      week1: "80.00",
      week2: "-",
      week3: "100.00",
      week4: "100.00",
      total: "280.00",
    },
    {
      key: "9",
      headings: "Total Payment (Inc. Tax)",
      week1: "360.00",
      week2: "280.00",
      week3: "320.00",
      week4: "380.00",
      total: "1150.00",
    },
  ];

  const jobbingColumns = [
    {
      title: "Headings",
      dataIndex: "headings",
      key: "headings",
    },
    {
      title: "01/01/2024 - 01/07/2024",
      dataIndex: "week1",
      key: "week1",
    },
    {
      title: "01/08/2024 - 01/14/2024",
      dataIndex: "week2",
      key: "week2",
    },
    {
      title: "01/14/2024 - 01/21/2024",
      dataIndex: "week3",
      key: "week3",
    },
    {
      title: "01/21/2024 - 01/28/2024",
      dataIndex: "week4",
      key: "week4",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const jobbingData = [
    {
      key: "1",
      headings: "Frame Count",
      week1: "20",
      week2: "30",
      week3: "40",
      week4: "10",
      total: "100",
    },
    {
      key: "2",
      headings: "Frame Sales",
      week1: "100.00",
      week2: "20.00",
      week3: "20.00",
      week4: "20.00",
      total: "160.00",
    },
    {
      key: "3",
      headings: "Lenses",
      week1: "100.00",
      week2: "30.00",
      week3: "30.00",
      week4: "30.00",
      total: "190.00",
    },
    {
      key: "4",
      headings: "Extras",
      week1: "100.00",
      week2: "40.00",
      week3: "40.00",
      week4: "40.00",
      total: "160.00",
    },
    {
      key: "5",
      headings: "Spec Discount",
      week1: "-100.00",
      week2: "-50.00",
      week3: "-50.00",
      week4: "-",
      total: "-200.00",
    },
    {
      key: "6",
      headings: "Spec Cost",
      week1: "100.00",
      week2: "60.00",
      week3: "-",
      week4: "-",
      total: "120.00",
    },
    {
      key: "7",
      headings: "No. of Specs",
      week1: "20",
      week2: "20",
      week3: "10",
      week4: "30",
      total: "80",
    },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Practice Summary Weekly Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Report Date"
                name="reportDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportDate && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Practice Summary Weekly Report for {reportDate}
          </Divider>

          <h3>Patient Payment (Inc Tax)</h3>
          <Table
            columns={patientPaymentColumns}
            dataSource={patientPaymentData}
            pagination={false}
            bordered
          />

          <h3 style={{ marginTop: "24px" }}>Jobbing (Exc Tax)</h3>
          <Table columns={jobbingColumns} dataSource={jobbingData} pagination={false} bordered />
        </Card>
      )}
    </>
  );
};

export default PracticeSummaryWeeklyReport;
