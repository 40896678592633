import { RouteObject } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";
import { RouteComponents } from "./routeComponents";
import PrivateRoute from "components/PrivateRoute";

const createRoute = (path: string, Component: JSX.Element, isPrivate = false): RouteObject => ({
  path,
  element: isPrivate ? <PrivateRoute element={Component} /> : Component,
});

// Authentication Routes
const getAuthRoutes = (): RouteObject[] => [
  createRoute(ROUTES.AUTH_ROUTES.LOGIN, <RouteComponents.LoginPage />),
  createRoute(ROUTES.AUTH_ROUTES.RESET_PASSWORD, <RouteComponents.ResetPasswordPage />),
];

// Dashboard Routes
const getDashboardRoutes = (): RouteObject[] => [
  createRoute(ROUTES.DASHBOARD, <RouteComponents.Dashboard />, true),
];

// Appointment Routes
const getAppointmentRoutes = (): RouteObject[] => [
  createRoute(ROUTES.APPOINTMENT, <RouteComponents.AppointmentPage />, true),
];

// Patient Records Routes
const getPatientRecordsRoutes = (): RouteObject[] => [
  createRoute(ROUTES.PATIENT_RECORDS.BASE, <RouteComponents.PatientRecord />, true),
  createRoute(ROUTES.PATIENT_RECORDS.CREATE, <RouteComponents.PatientRecordForm />, true),
  createRoute(ROUTES.PATIENT_RECORDS.UPDATE, <RouteComponents.PatientRecordForm />, true),
  createRoute(ROUTES.PATIENT_RECORDS.DETAIL, <RouteComponents.PatientRecordDetailsPage />, true),
];

// Subscription Routes
const getSubscriptionRoutes = (): RouteObject[] => [
  createRoute(ROUTES.SUBSCRIPTION.BASE, <RouteComponents.SubscriptionPage />, true),
  createRoute(ROUTES.SUBSCRIPTION.CREATE, <RouteComponents.SubscriptionForm />, true),
  createRoute(ROUTES.SUBSCRIPTION.UPDATE, <RouteComponents.SubscriptionForm />, true),
  createRoute(ROUTES.SUBSCRIPTION.DETAILS, <RouteComponents.SubscriptionCard />, true),
];

// Consultation Routes
const getConsultationRoutes = (): RouteObject[] => [
  createRoute(ROUTES.CONSULTATION_ROUTES.BASE, <RouteComponents.ConsultationPage />, true),
  createRoute(
    ROUTES.CONSULTATION_ROUTES.DETAIL(":id"),
    <RouteComponents.ConsultationDetailsPage />,
    true
  ),
];

// Inventory Routes (Grouped Together)
const getInventoryRoutes = (): RouteObject[] => [
  // Inventory Sundry Routes
  createRoute(ROUTES.INVENTORY.SUNDRY.BASE, <RouteComponents.SundryListPage />, true),
  createRoute(ROUTES.INVENTORY.SUNDRY.DETAILS, <RouteComponents.SundryDetailsPage />, true),
  createRoute(ROUTES.INVENTORY.SUNDRY.CREATE, <RouteComponents.SundryForm />, true),
  // Inventory Frame Routes
  createRoute(ROUTES.INVENTORY.FRAME.BASE, <RouteComponents.FrameListPage />, true),
  createRoute(ROUTES.INVENTORY.FRAME.CREATE, <RouteComponents.FrameForm />, true),
  createRoute(ROUTES.INVENTORY.FRAME.DETAILS, <RouteComponents.FrameDetailsPage />, true),
  // Inventory Report Routes
  createRoute(ROUTES.INVENTORY.ADJUSTMENT_REPORT, <RouteComponents.AdjustmentReport />, true),
  createRoute(ROUTES.INVENTORY.INVOICE_REPORT, <RouteComponents.InvoiceReport />, true),
  createRoute(ROUTES.INVENTORY.FRAME_STOCK_REPORT, <RouteComponents.FrameStockReport />, true),
  createRoute(ROUTES.INVENTORY.SUNDRY_STOCK_REPORT, <RouteComponents.SundryStockReport />, true),
  createRoute(ROUTES.INVENTORY.FRAME_APPROVAL, <RouteComponents.FrameApprovalPage />, true),
  createRoute(
    ROUTES.INVENTORY.FRAME_ORDERING_FROM_USAGE,
    <RouteComponents.FrameOrderingFromUsage />,
    true
  ),
  createRoute(
    ROUTES.INVENTORY.FRAME_BARCODE_BY_PURCHASE_DATE,
    <RouteComponents.FrameBarcodeReportByPurchaseDate />,
    true
  ),
  createRoute(ROUTES.INVENTORY.LENS_BARCODE_REPORT, <RouteComponents.LensBarcodeReport />, true),
  createRoute(
    ROUTES.INVENTORY.LENS_PRICE_LIST_REPORT,
    <RouteComponents.LensPriceListReport />,
    true
  ),
  createRoute(ROUTES.INVENTORY.FRAME_STOCKTAKE, <RouteComponents.FrameStocktakePage />, true),
];

// Master Setup Routes (Grouped Together)
const getMasterSetupRoutes = (): RouteObject[] => [
  // Supplier Routes
  createRoute(ROUTES.MASTER_SETUP.SUPPLIER.BASE, <RouteComponents.SupplierListPage />, true),
  createRoute(ROUTES.MASTER_SETUP.SUPPLIER.DETAILS, <RouteComponents.SupplierDetailsPage />, true),
  createRoute(ROUTES.MASTER_SETUP.SUPPLIER.CREATE, <RouteComponents.SupplierForm />, true),
  // Payment Type Routes
  createRoute(ROUTES.MASTER_SETUP.PAYMENT_TYPE.BASE, <RouteComponents.PaymentTypeList />, true),
  // Frame Group Routes
  createRoute(ROUTES.MASTER_SETUP.FRAME_GROUP.BASE, <RouteComponents.FrameGroupList />, true),
  // Lens Code Routes
  createRoute(ROUTES.MASTER_SETUP.LENS_CODE.BASE, <RouteComponents.LensCodeSpectacleList />, true),
  createRoute(
    ROUTES.MASTER_SETUP.LENS_CODE.CREATE,
    <RouteComponents.LensCodeSpectacleForm />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.LENS_CODE.DETAILS,
    <RouteComponents.LensCodeSpectacleDetails />,
    true
  ),
  createRoute(ROUTES.MASTER_SETUP.FRAME_TYPE.BASE, <RouteComponents.FrameTypeList />, true),
  createRoute(ROUTES.MASTER_SETUP.WORKING_HOURS.BASE, <RouteComponents.WorkingHoursConfig />, true),
  createRoute(
    ROUTES.MASTER_SETUP.APPOINTMENT_TYPE.BASE,
    <RouteComponents.AppointmentTypeList />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.APPOINTMENT_TYPE.CREATE,
    <RouteComponents.AppointmentTypeForm />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.PRACTITIONER_SETUP.BASE,
    <RouteComponents.PractitionerSetup />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.EMAIL_TEMPLATE.BASE,
    <RouteComponents.EmailTemplateSetup />,
    true
  ),
  createRoute(
    ROUTES.MASTER_SETUP.EMAIL_TEMPLATE.CREATE,
    <RouteComponents.AddEmailTemplate />,
    true
  ),
  createRoute(ROUTES.MASTER_SETUP.SMS_TEMPLATE.BASE, <RouteComponents.SMSTemplateSetup />, true),
  createRoute(ROUTES.MASTER_SETUP.SMS_TEMPLATE.CREATE, <RouteComponents.AddSMSTemplate />, true),
];

// User Management Routes
const getUserRoutes = (): RouteObject[] => [
  createRoute(ROUTES.USER.BASE, <RouteComponents.UserList />, true),
];

// Support Routes
const getSupportRoutes = (): RouteObject[] => [
  createRoute(ROUTES.HELP_AND_SUPPORT, <RouteComponents.HelpAndSupport />, true),
  createRoute(
    ROUTES.SUPPORT.SUPPORT_TICKET_TYPE.BASE,
    <RouteComponents.SupportTicketTypeList />,
    true
  ),
];

// Statistics Routes
const getStatisticsRoutes = (): RouteObject[] => [
  createRoute(
    ROUTES.STATISTICS_ROUTES.CONSULTATION_ITEM_REPORT.BASE,
    <RouteComponents.ConsultationItemReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.PATIENT_HISTORY_SUMMARY.BASE,
    <RouteComponents.PatientHistorySummary />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.PATIENT_OLD_NEW_REPORT.BASE,
    <RouteComponents.PatientNewOldReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.PATIENT_REPORT_BY_SUBURB.BASE,
    <RouteComponents.PatientReportBySuburbPage />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.RECALL_STATISTICS.BASE,
    <RouteComponents.RecallStatistics />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_PRESCRIPTION_REPORT.BASE,
    <RouteComponents.SpectaclePrescriptionReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.THERAPEUTIC_PRESCRIPTION_SUMMARY.BASE,
    <RouteComponents.TherapeuticPrescriptionSummary />,
    true
  ),
  createRoute(ROUTES.STATISTICS_ROUTES.KPI_REPORT.BASE, <RouteComponents.KPIReport />, true),
  createRoute(
    ROUTES.STATISTICS_ROUTES.FRAME_DETAILS_USAGE_REPORT.BASE,
    <RouteComponents.FrameDetailUsageReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.FRAME_STOCK_TURN_REPORT_BY_BRAND.BASE,
    <RouteComponents.FrameStockTurnReportByBrand />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_EXPORT_JOB.BASE,
    <RouteComponents.SpectacleExportJobs />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_FRAME_REPORT.BASE,
    <RouteComponents.SpectacleFrameReportPage />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_LENS_DETAILS_USAGE_REPORT.BASE,
    <RouteComponents.SpectacleLensTypeReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_ANALYSIS_BY_PRESCRIBER.BASE,
    <RouteComponents.SpectacleAnalysisByPrescriber />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_ANALYSIS_BY_DISPENSER.BASE,
    <RouteComponents.SpectacleAnalysisByDispenser />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_PROFIT_LOSS_REPORT.BASE,
    <RouteComponents.SpectacleProfitLossReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_LENS_EXTRA_REPORT.BASE,
    <RouteComponents.SpectacleLensExtraReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SPECTACLE_SUMMARY_SALE_REPORT.BASE,
    <RouteComponents.SpectacleSummarySaleReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.CONTACT_LENS_EXPORT_JOB.BASE,
    <RouteComponents.ContactLensExportJob />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.CONTACT_LENS_USAGE_REPORT_WITH_PRICES.BASE,
    <RouteComponents.ContactLensUsageReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.CONTACT_LENS_USAGE_REPORT_WITH_PRICES_BY_PRESCRIBER.BASE,
    <RouteComponents.ContactLensUsageReportWithPricesByPrescriber />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.CONTACT_LENS_USAGE_REPORT_WITH_RX.BASE,
    <RouteComponents.ContactLensUsageReportWithRx />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.SUNDRIES_USAGE_REPORT.BASE,
    <RouteComponents.SundriesUsageReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.TRANSITION_USAGE_SUMMARY.BASE,
    <RouteComponents.TransitionUsageSummary />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.PRACTICE_SUMMARY_WEEKLY_REPORT.BASE,
    <RouteComponents.PracticeSummaryWeeklyReport />,
    true
  ),
  createRoute(
    ROUTES.STATISTICS_ROUTES.PRACTICE_SUMMARY_REPORT.BASE,
    <RouteComponents.PracticeSummaryReport />,
    true
  ),
  createRoute(ROUTES.STATISTICS_ROUTES.FRAME_REPORT.BASE, <RouteComponents.FrameReport />, true),
];

// Financial Routes
const getFinancialRoutes = (): RouteObject[] => [
  createRoute(ROUTES.FINANCIAL_ROUTES.PETTY_CASH.BASE, <RouteComponents.PettyCashPage />, true),
  createRoute(ROUTES.FINANCIAL_ROUTES.BANKING.BASE, <RouteComponents.BankingPage />, true),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BULK_BILLING_ACCOUNTS.BASE,
    <RouteComponents.BulkBillingAccountsPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BULK_BILLING_PAYMENT.BASE,
    <RouteComponents.BulkBillingPaymentPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BULK_BILLING_PAYMENT.DETAILS,
    <RouteComponents.BulkBillingPaymentFormPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.OUTSTANDING_BULK_BILLING_ACCOUNTS.BASE,
    <RouteComponents.OutstandingBulkBillingAccounts />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.SUPPLIER_INVOICE_CREDIT_REPORT.BASE,
    <RouteComponents.SupplierInvoiceCreditReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BANKING_RECONCILIATION.BASE,
    <RouteComponents.BankReconciliation />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_SUMMARY_YEARLY_GRAPH.BASE,
    <RouteComponents.BillingSummary />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.SSF_BILLING_ITEM_SUMMARY.BASE,
    <RouteComponents.SSFBillingItemSummary />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_SEARCH_BY_ITEM_NUMBER.BASE,
    <RouteComponents.BillingSearchByItemNumber />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.PAYMENT_SUMMARY.BASE,
    <RouteComponents.PaymentSummaryPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.DELETED_BILLING_PAYMENT_REPORT.BASE,
    <RouteComponents.DeletedBillingPaymentReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.SUPPLIER_INVOICE_CREDIT_SUMMARY_REPORT.BASE,
    <RouteComponents.SupplierInvoiceCreditSummaryReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.EXPENSE_PAYMENT.BASE,
    <RouteComponents.ExpensePayment />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.EXPENSE_PAYMENT_REPORTS.BASE,
    <RouteComponents.ExpensePaymentReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_ITEM_CASH_SALE_REPORT.BASE,
    <RouteComponents.BillingItemCashSaleReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_ITEM_REPORT_BY_PROVIDER.BASE,
    <RouteComponents.BillingItemReportByProvider />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.DETAIL_PAYMENT_REPORT.BASE,
    <RouteComponents.DetailPaymentReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_SUMMARY_REPORT.BASE,
    <RouteComponents.BillingSummaryPage />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.REVERSE_NEGATIVE_BILLING_ITEM_REPORT.BASE,
    <RouteComponents.ReverseNegativeBillingItemReport />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.CONSULTATION_REPORT_WITHOUT_BILLING.BASE,
    <RouteComponents.ConsultationReportWithoutBilling />,
    true
  ),
  createRoute(
    ROUTES.FINANCIAL_ROUTES.BILLING_SUMMARY_BY_GROUP_REPORT.BASE,
    <RouteComponents.BillingSummaryByGroup />,
    true
  ),
];

export const routeConfigs = {
  authRoutes: getAuthRoutes(),
  dashboardRoutes: getDashboardRoutes(),
  appointmentRoutes: getAppointmentRoutes(),
  patientRecordsRoutes: getPatientRecordsRoutes(),
  subscriptionRoutes: getSubscriptionRoutes(),
  consultationRoutes: getConsultationRoutes(),
  inventoryRoutes: getInventoryRoutes(),
  masterSetupRoutes: getMasterSetupRoutes(),
  userRoutes: getUserRoutes(),
  supportRoutes: getSupportRoutes(),
  financialRoutes: getFinancialRoutes(),
  statisticsRoutes: getStatisticsRoutes(),
};
