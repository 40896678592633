import React, { useState } from "react";
import { Card, Row, Col, Form, DatePicker, Radio, Button, Table, Divider } from "antd";

const SpectacleAnalysisByPrescriber: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false);

  const handleReset = () => {
    form.resetFields();
    setShowReport(false);
  };

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns = [
    { title: "Optom", dataIndex: "optom", key: "optom" },
    { title: "Total No. of Patients", dataIndex: "totalPatients", key: "totalPatients" },
    { title: "Patients with 1 Pair", dataIndex: "onePair", key: "onePair" },
    { title: "Patients with 2 Pairs", dataIndex: "twoPairs", key: "twoPairs" },
    { title: "Patients with > 2 Pairs", dataIndex: "morePairs", key: "morePairs" },
    { title: "Total No. of Specs", dataIndex: "totalSpecs", key: "totalSpecs" },
    { title: "Total Cost Exc. Tax $", dataIndex: "totalCost", key: "totalCost" },
    { title: "Total Sale Exc. Tax $", dataIndex: "totalSale", key: "totalSale" },
  ];

  const data = [
    {
      key: "1",
      optom: "CN",
      totalPatients: 15,
      onePair: 24,
      twoPairs: 10,
      morePairs: 15,
      totalSpecs: 11,
      totalCost: 1700.0,
      totalSale: 3000.0,
    },
    {
      key: "2",
      optom: "DT",
      totalPatients: 25,
      onePair: 26,
      twoPairs: 10,
      morePairs: 10,
      totalSpecs: 22,
      totalCost: 5600.0,
      totalSale: 8000.0,
    },
    {
      key: "3",
      optom: "Total",
      totalPatients: 40,
      onePair: 50,
      twoPairs: 20,
      morePairs: 25,
      totalSpecs: 33,
      totalCost: 7300.0,
      totalSale: 11000.0,
    },
  ];

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={24}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 50px)",
            overflowY: "auto",
          }}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Report From Date"
                  name="reportFromDate"
                  rules={[{ required: true, message: "Please select the start date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Report To Date"
                  name="reportToDate"
                  rules={[{ required: true, message: "Please select the end date" }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Report Destination" name="destination" initialValue="screen">
                  <Radio.Group>
                    <Radio value="screen">To Screen</Radio>
                    <Radio value="printer">To Printer</Radio>
                    <Radio value="printerPrompt">To Printer with Prompt</Radio>
                    <Radio value="excel">To Excel Spreadsheet</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" gutter={16}>
              <Button onClick={handleReset} style={{ marginRight: 8 }}>
                Reset
              </Button>
              <Button type="primary" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Row>
          </Form>

          {showReport && (
            <>
              <Divider style={{ margin: "24px 0" }}>
                Spectacle Analysis By Prescriber Report From 10-10-2024 To 12-12-2024
              </Divider>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                summary={(pageData) => {
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={8} index={1}>
                        <Row style={{ padding: 16, background: "#f6f8fa" }}>
                          <Col span={8}>
                            <strong>Profit$:</strong> 75
                          </Col>
                          <Col span={8}>
                            <strong>Average Cost of Spectacle $:</strong> 325
                          </Col>
                          <Col span={8}>
                            <strong>Average Sale Price of Spectacle $:</strong> 400
                          </Col>
                          <Col span={8}>
                            <strong>Average Profit of Spectacle $:</strong> 88.0
                          </Col>
                          <Col span={8}>
                            <strong>Patient % with 1 Pair of Spectacle:</strong> 64%
                          </Col>
                          <Col span={8}>
                            <strong>Patient % with 2 Pairs of Spectacle:</strong> 26%
                          </Col>
                          <Col span={8}>
                            <strong>Patient % with 2 Pairs of Spectacle:</strong> 10%
                          </Col>
                        </Row>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SpectacleAnalysisByPrescriber;
