import React from "react";
import { Menu, Tooltip } from "antd";
import {
  SquaresFour,
  Stethoscope,
  TipJar,
  Calendar,
  Warehouse,
  GearSix,
  Users,
  Question,
  UserCircle,
  CurrencyDollarSimple,
  PresentationChart,
} from "@phosphor-icons/react";

interface MenuProps {
  onSelect: (e: any) => void;
  selectedKeys: string;
  openKeys: string[];
  onOpenChange: (e: string[]) => void;
}

const iconColor = (isSelected: boolean) => (isSelected ? "#1499B8" : "#4B5563");

const AppMenu: React.FC<MenuProps> = ({ onSelect, selectedKeys, openKeys, onOpenChange }) => {
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[selectedKeys]}
      onClick={onSelect}
      style={{ borderRight: 0, fontWeight: 500 }}
    >
      <Menu.Item
        key="/dashboard"
        icon={
          <SquaresFour size={18} color={iconColor(selectedKeys === "/dashboard")} weight="fill" />
        }
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        key="/consultation"
        icon={
          <Stethoscope
            size={18}
            color={iconColor(selectedKeys === "/consultation")}
            weight="fill"
          />
        }
      >
        Consultation
      </Menu.Item>
      <Menu.Item
        key="/subscription"
        icon={
          <TipJar size={18} color={iconColor(selectedKeys === "/subscription")} weight="fill" />
        }
      >
        Subscription
      </Menu.Item>
      <Menu.Item
        key="/appointment"
        icon={
          <Calendar size={18} color={iconColor(selectedKeys === "/appointment")} weight="fill" />
        }
      >
        Appointments
      </Menu.Item>
      <Menu.Item
        key="/user-management"
        icon={
          <Users size={18} color={iconColor(selectedKeys === "/user-management")} weight="fill" />
        }
      >
        User Management
      </Menu.Item>
      <Menu.Item
        key="/patient-records"
        icon={
          <UserCircle
            size={18}
            color={iconColor(selectedKeys === "/patient-records")}
            weight="fill"
          />
        }
      >
        Patient Management
      </Menu.Item>
      <Menu.SubMenu
        key="/master-setup"
        icon={
          <GearSix
            size={18}
            color={iconColor(selectedKeys.includes("/master-setup"))}
            weight="fill"
          />
        }
        title="Master Setup"
      >
        <Menu.Item key="/master-setup/lens-code">Lens Code Spectacle</Menu.Item>
        <Menu.Item key="/master-setup/supplier">Supplier Setup</Menu.Item>
        <Menu.Item key="/master-setup/payment-type">Payment Type</Menu.Item>
        <Menu.Item key="/master-setup/frame-group">Frame Group</Menu.Item>
        <Menu.Item key="/master-setup/frame-type">Frame Type</Menu.Item>
        <Menu.Item key="/master-setup/working-hours">Working Hours</Menu.Item>
        <Menu.Item key="/master-setup/appointment-type">Appointment Type</Menu.Item>
        <Menu.Item key="/master-setup/practitioner-setup">Practitioner Setup</Menu.Item>
        <Menu.Item key="/master-setup/support-ticket-type">Support Ticket Type</Menu.Item>
        <Menu.Item key="/master-setup/email-template-setup">Email Template Setup</Menu.Item>
        <Menu.Item key="/master-setup/sms-template-setup">SMS Template Setup</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/inventory"
        icon={
          <Warehouse
            size={18}
            color={iconColor(selectedKeys.includes("/inventory"))}
            weight="fill"
          />
        }
        title="Inventory"
      >
        <Menu.Item key="/inventory/frame">Frame Details</Menu.Item>
        <Menu.Item key="/inventory/sundry">Sundry Details</Menu.Item>
        <Menu.Item key="/inventory/adjustment-report">Adjustment Report</Menu.Item>
        <Menu.Item key="/inventory/invoice-report">Invoice Report</Menu.Item>
        <Menu.Item key="/inventory/frame-stock-report">Frame Stock Report</Menu.Item>
        <Menu.Item key="/inventory/sundry-stock-report">Sundry Stock Report</Menu.Item>
        <Menu.Item key="/inventory/frame-approval">Frame Approval</Menu.Item>
        <Menu.Item key="/inventory/frame-ordering-from-usage">
          <Tooltip title="Frame Ordering From Usage">Frame Ordering From Usage</Tooltip>
        </Menu.Item>
        {/* Last four items with tooltips */}

        <Menu.Item key="/inventory/frame-barcode-by-purchase-date-report">
          <Tooltip title="Print Frame Bar Code By Purchase Date">
            Print Frame Bar Code By Purchase Date
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/inventory/lens-barcode-report">
          <Tooltip title="Print Bar Code for Lens Code">Print Bar Code for Lens Code</Tooltip>
        </Menu.Item>

        <Menu.Item key="/inventory/lens-price-list-report">
          <Tooltip title="Print Price List for Lens Code">Print Price List for Lens Code</Tooltip>
        </Menu.Item>

        <Menu.Item key="/inventory/frame-stocktake-by-barcode">
          <Tooltip title="Frame Stocktake by BarCode">Frame Stocktake by BarCode</Tooltip>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/financial"
        icon={
          <CurrencyDollarSimple
            size={18}
            color={iconColor(selectedKeys.includes("/financial"))}
            weight="fill"
          />
        }
        title="Financial"
      >
        <Menu.Item key="/financial/petty-cash">
          <Tooltip title="Petty Cash">Petty Cash</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/banking">
          <Tooltip title="Banking">Banking</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/bulk-billing-accounts">
          <Tooltip title="Bulk Billing Accounts">Bulk Billing Accounts</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/bulk-billing-payment">
          <Tooltip title="Bulk Billing Payments">Bulk Billing Payments</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/bulk-billing-outstanding-accounts">
          <Tooltip title="Outstanding Bulk Billing Accounts">
            Outstanding Bulk Billing Accounts
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/supplier-invoice-credit-report">
          <Tooltip title="Supplier Invoice / Credit Report">
            Supplier Invoice / Credit Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/banking-reconciliation">
          <Tooltip title="Bank Reconciliation">Bank Reconciliation</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-reports-summary-yearly-graph">
          <Tooltip title="Billing Summary Yearly Graph">Billing Summary Yearly Graph</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-reports-ssf-item-summary">
          <Tooltip title="SSF Billing Item Summary">SSF Billing Item Summary</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-serach-from-item-number">
          <Tooltip title="Billing Search By Item No.">Billing Search By Item No.</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/payment-summary">
          <Tooltip title="Payment Summary">Payment Summary</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/deleted-billing-payment-report">
          <Tooltip title="Deleted Billing / Payment Report">
            Deleted Billing / Payment Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/supplier-invoice-credit-summary-report">
          <Tooltip title="Supplier Invoice / Credit Summary Report">
            Supplier Invoice / Credit Summary Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/expense-payment">
          <Tooltip title="Expense Payment">Expense Payment</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/expense-payment-report">
          <Tooltip title="Expense Payment Report">Expense Payment Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-item-cash-sale-report">
          <Tooltip title="Billing Item / Cash Sale Report">Billing Item / Cash Sale Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-item-by-provider-report">
          <Tooltip title="Billing Item Report By Provider">Billing Item Report By Provider</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/detail-payment-report">
          <Tooltip title="Detail Payment Report">Detail Payment Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-summary-report">
          <Tooltip title="Billing Summary">Billing Summary</Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/reverse-negative-billing-item-report">
          <Tooltip title="Reverse / Negative Billing Item Report">
            Reverse / Negative Billing Item Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/consultation-report-without-billing">
          <Tooltip title="Consultation Report Without Billing">
            Consultation Report Without Billing
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/financial/billing-summary-by-group-report">
          <Tooltip title="Billing Summary By Group">Billing Summary By Group</Tooltip>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu
        key="/statistics"
        icon={
          <PresentationChart
            size={18}
            color={iconColor(selectedKeys.includes("/statistics"))}
            weight="fill"
          />
        }
        title="Statistics"
      >
        <Menu.Item key="/statistics/consultation-item-report">
          <Tooltip title="View detailed consultation item reports">
            Consultation Item Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/patient-history-summary">
          <Tooltip title="Review patient history summaries">Patient History Summary</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/patient-old-new-report">
          <Tooltip title="Analyze reports of old vs. new patients">
            Patient (Old/New) Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/patient-report-by-suburb">
          <Tooltip title="Generate patient reports by suburb">Patient Report By Suburb</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/recall-statistics">
          <Tooltip title="Access recall statistics data">Recall Statistics</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-prescription-report">
          <Tooltip title="View spectacle prescription reports">
            Spectacle Prescription Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/therapeutic-prescription-summary">
          <Tooltip title="Summarize therapeutic prescriptions">
            Therapeutic Prescription Summary
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/kpi-report">
          <Tooltip title="Key Performance Indicator Report">KPI Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/frame-details-usage-report">
          <Tooltip title="Details and usage of frames report">Frame Detail Usage Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/frame-stock-turn-report-by-brand">
          <Tooltip title="Frame stock turnover report by brand">
            Frame Stock Turn Report By Brand
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-export-job">
          <Tooltip title="Export job details for spectacles">Spectacle Export Jobs</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-frame-report">
          <Tooltip title="Report on spectacle frame details">Spectacle Frame Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-lens-type-report">
          <Tooltip title="Spectacle lens type report">Spectacle Lens Type Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-lens-details-usage-report">
          <Tooltip title="Usage details for spectacle lenses">
            Spectacle Lens Detail Usage Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-analysis-by-prescriber">
          <Tooltip title="Spectacle analysis by prescriber">
            Spectacle Analysis By Prescriber
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-analysis-by-dispenser">
          <Tooltip title="Spectacle analysis by dispenser">Spectacle Analysis By Dispenser</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-profit-loss-report">
          <Tooltip title="Profit and loss report for spectacles">
            Spectacle Profit Loss Report
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-lens-extra-report">
          <Tooltip title="Extra report for spectacle lenses">Spectacle Lens Extra Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/spectacle-summary-sale-report">
          <Tooltip title="Summary of spectacle sales">Spectacle Summary Sale Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/contact-lens-export-job">
          <Tooltip title="Export job details for contact lenses">Contact Lens Extra Job</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/contact-lens-usage-report-with-prices">
          <Tooltip title="Usage report for contact lenses with prices">
            Contact Lens Usage Report With Prices
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/contact-lens-usage-report-with-prices-by-prescriber">
          <Tooltip title="Contact lens usage report by prescriber with prices">
            Contact Lens Usage Report With Prices By Prescriber
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/contact-lens-usage-report-with-rx">
          <Tooltip title="Contact lens usage report with prescription details">
            Contact Lens Usage Report With Rx
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/sundries-usage-report">
          <Tooltip title="Report on sundries usage">Sundries Usage Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/transition-usage-summary">
          <Tooltip title="Summary report on transition usage">Transition Usage Summary</Tooltip>
        </Menu.Item>

        <Menu.Item key="/statistics/practice-summary-weekly-report">
          <Tooltip title="Practice Summary Weekly Report">Practice Summary Weekly Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/practice-summary-report">
          <Tooltip title="Summary report on transition usage">Practice Summary Report</Tooltip>
        </Menu.Item>
        <Menu.Item key="/statistics/frame-report">
          <Tooltip title="Frame Report">Frame Report</Tooltip>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.Item
        key="/help-support"
        icon={
          <Question size={18} color={iconColor(selectedKeys === "/help-support")} weight="fill" />
        }
      >
        Help and Support
      </Menu.Item>
    </Menu>
  );
};

export default AppMenu;
