import React, { useState } from "react";
import { Card, Form, DatePicker, Radio, Button, Divider, Row, Col } from "antd";
import type { Dayjs } from "dayjs";

const ContactLensExportJob: React.FC = () => {
  const [showReport, setShowReport] = useState(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>("all");

  const handleGenerateReport = async () => {
    try {
      await form.validateFields();
      setShowReport(true);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <Card style={{ margin: 24, borderRadius: 4, boxShadow: "0px 8px 40px -10px #00000014" }}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Report From Date" name="startDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Report To Date" name="endDate" rules={[{ required: true }]}>
              <DatePicker<Dayjs> style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Status" name="status" initialValue="all">
              <Radio.Group onChange={(e) => setStatus(e.target.value)}>
                <Radio value="all">All</Radio>
                <Radio value="completed">Completed</Radio>
                <Radio value="pending">Pending</Radio>
                <Radio value="exported">Exported</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Button
            onClick={() => {
              form.resetFields();
              setShowReport(false);
              setStatus("all");
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={handleGenerateReport}>
            Generate Report
          </Button>
        </Row>
      </Form>

      {showReport && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Contact Lens Export Jobs ({status.toUpperCase()})
          </Divider>
          <div style={{ padding: 24 }}>
            {form.getFieldValue("startDate")?.format("DD-MM-YYYY")} to{" "}
            {form.getFieldValue("endDate")?.format("DD-MM-YYYY")}
          </div>
          {/* Add your report content/data here */}
        </>
      )}
    </Card>
  );
};

export default ContactLensExportJob;
