import React, { useState } from "react";
import { Button, Card, Input, Table, Typography, Row, Col, Modal, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";

const EmailTemplateSetup = () => {
  const [searchText, setSearchText] = useState("");
  const [templates, setTemplates] = useState<any[]>([]);
  const navigate = useNavigate();

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
      width: 80,
    },
    {
      title: "Template Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
    },
    {
      title: "Last Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
    {
      title: "Last Updated On",
      dataIndex: "updatedOn",
      key: "updatedOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const handleAddTemplate = () => {
    navigate(ROUTES.MASTER_SETUP.EMAIL_TEMPLATE.CREATE);
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Col>
          <Input.Search
            placeholder="Search Template Title"
            style={{ width: 300 }}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddTemplate}>
            Add New Template
          </Button>
        </Col>
      </Row>

      <Table columns={columns} dataSource={templates} bordered pagination={false} />
    </>
  );
};

export default EmailTemplateSetup;
