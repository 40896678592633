import React, { useState } from "react";
import {
  DatePicker,
  InputNumber,
  Checkbox,
  Button,
  Card,
  Form,
  Row,
  Col,
  Radio,
  Input,
  Table,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import styled from "@emotion/styled";
import { SpaceContext } from "antd/es/space";
import { Space } from "antd/lib";

// Styled components
const Container = styled.div`
  padding: 20px;
`;

const ReportSection = styled(Card)`
  margin-top: 20px;
`;

const KPIReport: React.FC = () => {
  const [form] = Form.useForm();
  const [showReport, setShowReport] = useState(false); // State to control report visibility

  const handleIncludePreviousYear = (e: CheckboxChangeEvent) => {
    console.log("Include Previous Year:", e.target.checked);
  };

  const handleRecordDeclaration = (e: any) => {
    console.log("Record Declaration:", e.target.value);
  };

  const handleGenerateReport = async () => {
    const values = await form.validateFields();
    setShowReport(true); // Show the report when the button is clicked
  };

  // Sample report data
  const billingData = [
    { key: "1", kpiHeading: "Sale after discount 10-10-2024 to 31-12-2024", numbers: "$125" },
    { key: "2", kpiHeading: "Consultation", numbers: "$456 - 20.02" },
    { key: "3", kpiHeading: "Frame Sale", numbers: "$566 - 4.6%" },
    { key: "4", kpiHeading: "Sunglasses as Frame Sale", numbers: "$566 - 27.6" },
    { key: "5", kpiHeading: "Frame Discount", numbers: "-$566" },
    { key: "6", kpiHeading: "Lens Sale", numbers: "$120 - 47.9" },
    { key: "7", kpiHeading: "Lens Discount", numbers: "-$565" },
    { key: "8", kpiHeading: "CL Sales", numbers: "$100 - 1.3%" },
    { key: "9", kpiHeading: "CL Discount", numbers: "-$100" },
    { key: "10", kpiHeading: "Sunglasses Sale", numbers: "$100" },
    { key: "11", kpiHeading: "Other Sales", numbers: "$100 - 2.2%" },
    { key: "12", kpiHeading: "Other Discounts", numbers: "$100" },
    { key: "13", kpiHeading: "Overall Discount %", numbers: "7.5%" },
    { key: "14", kpiHeading: "No. of Patient Bills Discounted", numbers: "56" },
    { key: "15", kpiHeading: "Average Discount Per Bill", numbers: "$100" },
    { key: "16", kpiHeading: "Total GST", numbers: "$800" },
    { key: "17", kpiHeading: "Total Reversed Bill", numbers: "-$500" },
    { key: "18", kpiHeading: "Total Reverse Linked Bill", numbers: "$400" },
    { key: "19", kpiHeading: "Sale after Discount MTD 10-10-2024 to 31-12-2024", numbers: "$300" },
    { key: "20", kpiHeading: "Sale after Discount YTD 10-10-2024 to 31-12-2024", numbers: "$200" },
  ];

  const spectacleData = [
    {
      key: "1",
      kpiHeading: "No. of Spec Jobs (exc. Warranties, Vet Aff & Gov Spec Pigs)",
      numbers: "125",
    },
    { key: "2", kpiHeading: "No. of New Frames", numbers: "456 - 47.9" },
    { key: "3", kpiHeading: "No. of Own Frames", numbers: "566 - 47.9" },
    { key: "4", kpiHeading: "No. of Sunglasses as Frames", numbers: "120 - 47.9" },
    { key: "5", kpiHeading: "Lens Pairs Sold", numbers: "565" },
    { key: "6", kpiHeading: "Avg Spectacle Sale Price (Inc. GST)", numbers: "$100" },
    { key: "7", kpiHeading: "Avg Frame Sale Price (Inc. GST)", numbers: "$100" },
    { key: "8", kpiHeading: "Avg Lens Sale Price (Inc. GST)", numbers: "$100" },
    { key: "9", kpiHeading: "No. of Patients with 2 pairs", numbers: "100 - 25.8%" },
    { key: "10", kpiHeading: "No. of Patients with more than 2 pairs", numbers: "100 - 5.0%" },
    { key: "11", kpiHeading: "No. of Veterans Spectacle Jobs", numbers: "100" },
    { key: "12", kpiHeading: "Avg Veterans Spectacle Sale Price (Inc. GST)", numbers: "$88" },
    { key: "13", kpiHeading: "Veterans Discount", numbers: "$56" },
    { key: "14", kpiHeading: "No. of Govt. Spec Scheme Spectacle Jobs", numbers: "100" },
    { key: "15", kpiHeading: "Avg Govt. Sch Spec Sale Price (Inc. GST)", numbers: "$800" },
    { key: "16", kpiHeading: "Govt. Spec Job Discount", numbers: "$50" },
  ];

  const warrantiesData = [
    { key: "1", kpiHeading: "No. of Warranties - Practice", numbers: "100 - 25.8%" },
    { key: "2", kpiHeading: "No. of Warranties - Supplier", numbers: "100 - 5.0%" },
  ];

  // Columns for the tables
  const columns = [
    {
      title: "KPI Headings",
      dataIndex: "kpiHeading",
      key: "kpiHeading",
    },
    {
      title: "Numbers",
      dataIndex: "numbers",
      key: "numbers",
    },
  ];

  const handleSubmit = () => {};

  return (
    <Container>
      <Card>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="End Date" name="endDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="No. of Appointments Hours/Week" name="appointmentHours">
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="No. of Opening Hours/Week" name="openingHours">
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Optometrist" name="openingHours">
                <Input min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          {/* Checkboxes */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="includePreviousYear">
                <Checkbox onChange={handleIncludePreviousYear}>Include Previous Year?</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* Record Declaration Radio Group */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Record Declaration" name="recordDeclaration">
                <Radio.Group onChange={handleRecordDeclaration}>
                  <Radio value="To Screen">To Screen</Radio>
                  <Radio value="To Preface">To Preface</Radio>
                  <Radio value="To Preface with Prompt">To Preface with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row justify={"end"}>
            <Space>
              <Button htmlType="submit">Reset</Button>
              <Button type="primary" htmlType="submit" onClick={handleGenerateReport}>
                Generate Report
              </Button>
            </Space>
          </Row>
        </Form>
      </Card>

      {showReport && (
        <ReportSection title="Key Performance Indicator From 10-10-2024 To 31-12-2024">
          <h3>Billing (Exc. GST)</h3>
          <Table dataSource={billingData} columns={columns} pagination={false} />

          <h3>Spectacle</h3>
          <Table dataSource={spectacleData} columns={columns} pagination={false} />

          <h3>Warranties Job</h3>
          <Table dataSource={warrantiesData} columns={columns} pagination={false} />
        </ReportSection>
      )}
    </Container>
  );
};

export default KPIReport;
